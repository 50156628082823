import React, { useEffect, useRef, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import style from "./homeheader.module.css";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../../utils/api";
import {
  Col,
  Container,
  Dropdown,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import useGetUser from "../../../../customHook/useGetUser";
import { logout } from "../../../../apis/authApis";
import { getStateForCountry } from "../../../../apis/CenterApis";

const HomeHeader = () => {
  const section1Ref = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getToken();
  // const [mobileWidth,setMobileWidth]=useState(window.innerWidth <=768)

  // console.log("mobile",mobileWidth);
  useGetUser();
  const { user } = useSelector((state) => state.user);
  const dropdownRef = useRef(null);
  const countryStateRef = useRef(null);

  const [active, setActive] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDonateDropdown, setShowDontaeDropdown] = useState(false);
  const [showStateDropdown, setShowStateDropdown] = useState(false);
  const [showAboutDropdown, setShowAboutDropdown] = useState(false);

  // const [country,setCountry]=useState()
  // const [states,setStates]=useState(null)
  const [selectedState, setSelctedState] = useState(null);

  const [expanded, setExpanded] = useState(false);
  const countrylist = [
    {
      isoCode: "CA",
      name: "Canada",
    },
    {
      isoCode: "MX",
      name: "Mexico",
    },
    {
      isoCode: "PR",
      name: "Puerto Rico",
    },
    {
      isoCode: "US",
      name: "United States",
    },
  ];

  useEffect(() => {
    setActive(location.pathname);
  }, [location]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current &&!dropdownRef.current.contains(event.target)) {
        // setShowDropdown(false);
        // setShowAboutDropdown(false);
      }
      
  
      if (
        countryStateRef.current &&
        !countryStateRef.current.contains(event.target)
      ) {
        setShowDontaeDropdown(false);
        setShowStateDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const scrollToSection = (id) => {
    navigate(`/aboutus#${id}`);
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      setShowAboutDropdown(false); // Close the dropdown after scrolling
    }
  };

  const hanndleCountryClick = (value) => {
    if (value?.name === "Puerto Rico") {
      navigate("/search-center", { state: value.name });
      setShowDontaeDropdown(false);
      setShowStateDropdown(false);
    } else {
      dispatch(getStateForCountry({ country: value.name })).then((res) => {
        if (res?.states?.length > 0) {
          setSelctedState(res?.states);
          setShowStateDropdown(true);
        } else {
          setShowStateDropdown(false);
        }
      });
    }
  };

  const handleStateClick = (value) => {
    navigate("/search-center", { state: value });
    setShowStateDropdown(false);
    setShowDontaeDropdown(false);
  };

  // useEffect(() => {
  //   const handleResize = () => {
  //     setMobileWidth(window.innerWidth <= 768);
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, []);

  return (
    <>
      <div className={style.headbaground}>
        <div className="container">
          <div className={style.headerhead}>
            <h1 className="mb-0">
              {" "}
              <span>24 Hour Hotline: </span>(800) 712-Help(4357)
            </h1>
          </div>
        </div>
      </div>
      <div className={style.header_main}>
        {/* {mobileView && (

      )} */}

        <div className={style.mobilenavbar}>
          <Navbar
            expand="lg"
            expanded={expanded}
            className={style.mobilenavbar}>
            <Row className="align-items-center w-100">
              <Col xs={6}>
                <Navbar.Brand as={Link} to="/" onClick={() => setActive(1)}>
                  <img
                    className={style.header_logo}
                    src="/whitelogo.svg"
                    alt="logo"
                  />
                </Navbar.Brand>
              </Col>
              <Col xs={6} className="text-right">
                <div className={style.navbartoggle}>
                  <Navbar.Toggle
                    className={style.navbartogglericon}
                    aria-controls="basic-navbar-nav"
                    onClick={() => setExpanded(!expanded)} // Toggle properly without premature closing
                  />
                </div>
              </Col>
            </Row>

            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className={style.navbar}>
                <Link
                  className="p-1 border-0"
                  to="/aboutus/"
                  onClick={handleToggle}>
                  About Us
                </Link>
                <Link
                target="_blank"
                className="p-1 border-0"
                to="https://supportafterabortion.com/resources/unraveled-roots/"
                onClick={handleToggle}>
                Unraveled Roots
              </Link>
{/* 
                <Link
                  className="p-1 border-0"
                  to="/contactus"
                  onClick={handleToggle}>
                  Contact Us
                </Link> */}
              

                <Link
                  className="p-1 border-0"
                  to="/SupportMen.pdf"
                  target="_blank"
                  onClick={() => {
                    setActive("/SupportMen.pdf");
                    setExpanded(false);
                  }}>
                  Impact on Men
                </Link>

                <Link
                  className=" p-1 border-0"
                  to="/donate-to-us/"
                  onClick={handleToggle}>
                  Donate
                </Link>
                <Link
                className="p-1 border-0"
                to="/center-register"
                onClick={handleToggle}>
                Affiliate-sign up
              </Link>
                {/* <Link
               className="p-1 border-0"
              to="/aboutus/"
              onClick={() => {
                setActive("/aboutus/");
                setExpanded(false);
              }}>
              Abortion Reversal Pill
            </Link> */}

                {!token && (
                  <button
                    className={`${style.loginbtn}`}
                    onClick={() => {
                      setActive();
                      navigate("/login");
                      setExpanded(false);
                    }}>
                    Login
                  </button>
                )}
                {token && (
                  <div ref={dropdownRef}>
                    <button
                      className={style.loginbtn}
                      onClick={() => setShowDropdown(!showDropdown)}>
                      {user?.firstName}
                    </button>
                    <Dropdown.Menu className={style.drpdwn} show={showDropdown}>
                      <Dropdown.Item
                        onClick={() => {
                          handleToggle()
                          setShowDropdown(false);
                          setActive();
                          setExpanded(false); // Close the navbar only after the action
                          navigate("/profile");
                        }}>
                        View Profile
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setShowDropdown(false);
                          setExpanded(false); // Close the navbar only after the action
                          dispatch(logout({ navigate }));
                        }}>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </div>
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>

        <div className={style.smallscreennavbar}>
          <div className={style.smallhead}>
            <div>
              <Link to="/" onClick={() => setActive(1)}>
                <img
                  className={style.header_logo}
                  src="/whitelogo.svg"
                  alt="logo"
                />
              </Link>
            </div>
            <div>
              {!token && (
                <button
                  className={style.loginbtn}
                  type="button"
                  onClick={() => {
                    setActive();
                    navigate("/login");
                  }}>
                  Login
                </button>
              )}
              {token && (
                <div ref={dropdownRef}>
                  <button
                    className={style.userbtn}
                    type="button"
                    onClick={() => {
                      setShowDropdown(!showDropdown);
                    }}>
                    {user?.firstName}
                  </button>
                  <Dropdown.Menu className={style.drpdwn} show={showDropdown}>
                    <Dropdown.Item
                      onClick={() => {
                        setShowDropdown(false);
                        setActive();
                        navigate("/profile");
                      }}>
                      View Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setShowDropdown(false);
                        dispatch(logout({ navigate }));
                      }}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </div>
              )}
            </div>
          </div>

          <div>
            <Nav className={style.navbar}>
              {/* <Link className="p-1 border-0" to="/" onClick={handleToggle}>
                Home
              </Link> */}
               <Link
                  className="p-1 border-0"
                  to="/aboutus/"
                  onClick={handleToggle}>
                  About Us
                </Link>
                <Link
                  className="p-1 border-0"
                  target="_blank"
                  to="https://supportafterabortion.com/resources/unraveled-roots/"
                  onClick={handleToggle}>
                    Unraveled Roots
                </Link>

              {/* <Link
                className="p-1 border-0"
                to="/aboutus/"
                onClick={handleToggle}>
                About Us
              </Link> */}

              <Link
                className="p-1 border-0"
                to="/SupportMen.pdf"
                target="_blank"
                onClick={handleToggle}>
                Impact on Men
              </Link>

              {/*              
                <Link
                  className="p-1 border-0"
                  to="/search-center"
                  onClick={handleToggle}>
                Abortion Reversal Pill
                </Link> */}

              {/* <Link
                className="p-1 border-0"
                to="/contactus"
                onClick={handleToggle}>
                Contact Us
              </Link> */}
              <Link
                className="p-1 border-0"
                to="/center-register"
                onClick={handleToggle}>
                Affiliate-sign up
              </Link>
              <Link
                className="p-1 border-0"
                to="/donate-to-us"
                onClick={handleToggle}>
                Donate
              </Link>
              
            </Nav>
          </div>
        </div>

        <div className={`container  ${style.bigscreen}`}>
          <Nav className={style.navbar}>
            <div>
              <Link
                to="/"
                onClick={() => setActive(1)}
                style={{ borderBottom: "0px" }}>
                <img
                  className={style.header_logo}
                  src="/whitelogo.svg"
                  alt="logo"
                />
              </Link>
            </div>
            <div className={style.navmenus}>
              {/* <div className={style.nav}>
                <Link
                  className={active === "/" ? style.navactive : ""}
                  to="/"
                  onClick={() => setActive("/")}>
                  Home
                </Link>
              </div> */}
              <div className={`position-relative ${style.nav}`}
              onMouseEnter={() => setShowAboutDropdown(true)}  // Open dropdown on hover
              onMouseLeave={() => setShowAboutDropdown(false)} 
              
              >
                {/* <div
                  ref={dropdownRef}
                  // onMouseEnter={() => setShowAboutDropdown(true)}
                  // onMouseLeave={()=>setShowAboutDropdown(false)}
                 
                > */}
                  <Link
                    className={active === "/aboutus/" ? style.navactive : ""}
                    to="/aboutus/"
                    onClick={() => {
                 
                      setActive("/aboutus/");
                    }}>
                    About Us
                  </Link>

                  <Dropdown.Menu
                  
                    className={style.drpdwn}
                    show={showAboutDropdown}>
                    <Dropdown.Item
                      className={`${style.drpcontent} ${style.cursor_hand}`}
                      
                      onClick={() => {scrollToSection("coresection")
                        setShowAboutDropdown(false)}
                       }>
                      Core Beliefs
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={`${style.cursor_hand}`}
                      onClick={() => {scrollToSection("doitsection")
                        setShowAboutDropdown(false)}
                      }>
                      How We Do It
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={`${style.cursor_hand}`}
                      onClick={() => {scrollToSection("foundersection")
                        setShowAboutDropdown(false)}
                      }>
                      About the Founder
                    </Dropdown.Item>
                    <Dropdown.Item
                     onClick={() =>
                      setShowAboutDropdown(false)
                     }
                      as="a"
                      target="_blank"
                      href="https://supportafterabortion.com/resources/unraveled-roots/">
                      Unraveled Roots
                    </Dropdown.Item>

                    <Dropdown.Item
                      className={`${style.drpcontent} ${style.cursor_hand}`}
                      
               >
                     HBCU Project - Coming Soon
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </div>

                <div className={style.nav}>
                <Link
                  className={
                    active === "https://supportafterabortion.com/resources/unraveled-roots/" ? style.navactive : ""
                  }
                  to="https://supportafterabortion.com/resources/unraveled-roots/"
                  target="_blank"
                  onClick={() => setActive("https://supportafterabortion.com/resources/unraveled-roots/")}>
                 Unraveled Roots
                </Link>
              </div>
              {/* </div> */}

              <div className={style.nav}>
                <Link
                  className={
                    active === "/SupportMen.pdf" ? style.navactive : ""
                  }
                  to="/SupportMen.pdf"
                  target="_blank"
                  onClick={() => setActive("/SupportMen.pdf")}>
                  Impact on Men
                </Link>
              </div>

              {/* <div className={style.nav}>
                <Link
                  className={active === "/aboutus/" ? style.navactive : ""}
                  to="/aboutus/"
                  onClick={() => setActive("/aboutus/")}>
              Abortion Reversal Pill
                </Link>
              </div> */}

            
              {/* <div>
              <Link
                  className={active === "/contactus" ? style.navactive : ""}
                  to="/contactus"
                  onClick={() => setActive("/contactus")}
                >
                  Contact Us
                </Link>
                </div> */}
              <div className={style.nav}>
                <Link
                  className={`${
                    active === "/center-register" ? style.navactive : ""
                  } `}
                  to="/center-register"
                  onClick={() => setActive("/center-register")}>
                  Affiliate-sign up
                </Link>
              </div>
              <div className={style.nav}>
                <Link
                  className={`${
                    active === "/donate-to-us" ? style.navactive : ""
                  } `}
                  to="/donate-to-us"
                  onClick={() => setActive("/donate-to-us")}>
                  Donate
                </Link>
              </div>

              {/* <div
                ref={countryStateRef}
                className={`position-relative ${style.nav}`}>
                <Link
                  onClick={() => {
                    setShowDontaeDropdown(true);
                  }}>
                  Donate
                </Link>
                <Dropdown.Menu
                  className={` ${style.drpdwn}`}
                  show={showDonateDropdown}>
                  <Dropdown.Item disabled className={style.country}>
                    Countries
                  </Dropdown.Item>
                  {countrylist?.map((value, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => {
                        hanndleCountryClick(value);
                      }}>
                      {value.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
                <Dropdown.Menu
                  className={`position-absolute ${style.stateDrpDwm}`}
                  show={showStateDropdown}>
                  <Dropdown.Item disabled className={style.state}>
                    States
                  </Dropdown.Item>
                  {selectedState?.map((value, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => handleStateClick(value)}>
                      {value}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </div> */}
            </div>
            <div>
              {!token && (
                <button
                  className={style.loginbtn}
                  type="button"
                  onClick={() => {
                    setActive();
                    navigate("/login");
                  }}>
                  {" "}
                  Login
                </button>
              )}
              {token && (
                <div ref={dropdownRef}>
                  <button
                    className={style.loginbtn}
                    type="button"
                    onClick={() => {
                      setShowDropdown(!showDropdown);
                    }}>
                    {user?.firstName}
                  </button>
                  <Dropdown.Menu className={style.drpdwn} show={showDropdown}>
                    <Dropdown.Item
                      onClick={() => {
                        setShowDropdown(false);
                        setActive();
                        navigate("/profile");
                      }}>
                      View Profile
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setShowDropdown(false);
                        dispatch(logout({ navigate }));
                      }}>
                      Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </div>
              )}
            </div>
          </Nav>
        </div>
      </div>
    </>
  );
};

export default HomeHeader;
