import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Payment from '../pages/payment/Payment'
import Dashboard from '../pages/dashboard/Dashboard'

const MainRoutes = () => {
  return (
    <Routes>
      <Route index element={<Dashboard />}/>
      <Route path="payment" element={<Payment />}/>
      {/* <Route path="tasks" element={<DashboardTasks />} /> */}
     {/* </Route> */}
  </Routes>
  )
}

export default MainRoutes