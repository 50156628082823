import React from 'react';
import { Navigate } from 'react-router-dom';
import { getToken } from '../utils/api';
// import useGetUser from 'hooks/useGetUser';
import useGetUser from '../customHook/useGetUser';

const ProtectedRoute = ({ children }) => {
  const token = getToken();
  useGetUser();
  if (!token) {
    return <Navigate to="/login" />;
  }
  return children;
  
};

export default ProtectedRoute;