// import { getUserDetails } from 'api/userApi';
import { getUserDetails } from "../apis/userApis";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getToken } from "../utils/api";
import { refreshToken, logout } from "../apis/authApis";
import { isExpired } from "../utils/handleTolkenExpiration";
import { useNavigate } from "react-router-dom";

const useGetUser = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const token = getToken();
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    const refreshtoken = JSON.parse(localStorage.getItem("refreshToken"));
    const accessToken = JSON.parse(localStorage.getItem("token"));
    const checkRefresh = isExpired(refreshtoken);
    if (checkRefresh === true) {
      dispatch(logout({navigate}));
    }
    const checkAccess = isExpired(accessToken);
    if (checkAccess === true) {
      dispatch(refreshToken());
    }
    if (token && !user) {
        dispatch(getUserDetails());
      }
  }, [token, user]);
  return;
};

export default useGetUser;
