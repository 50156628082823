

import React, { useState } from "react";
import style from "./termservice.module.css";

import { Col, Row, Button, Form, InputGroup, Spinner } from "react-bootstrap";
const TermOfService = () => {

  return (
    <div>
      <div className={style.privacyPolicyContainer}>
        <div className="my-5 ">
          <div className={style.privacyHeading}>
            {" "}
            Terms of Service
            <br />{" "}
          </div>
        </div>
        <div className="my-5">
        
              <div className={style.sectionHeading}>Site Terms of Service </div>
              <div className={style.privacyText}>
                These terms of use apply to your use of the I Stand With The Forgotten Women
                website and all associated services (including but not limited
                to, e-mail received from We Care Texas, and content embedded on
                other websites) provided by We Care Texas. If you browse or
                donate through I Stand With The Forgotten Women, you accept these conditions.
              </div>
          
        </div>
        <div className="my-5">
       
              <div className={style.sectionHeading}>Privacy Policy </div>
              <div className={style.privacyText}>
                These terms of use include our <a href="/privacy-policy">privacy policy,</a> which describes
                how we use your personal information.
              </div>
          
        </div>

        <div className="my-5">
        
              <div className={style.sectionHeading}>Accuracy </div>
              <div className={style.privacyText}>
                We work hard to make sure all information provided by We Care
                Texas is as accurate as possible. However, this information is
                presented without warranty, either express or implied, as to its
                accuracy, timeliness, or completeness, and is not intended to
                replace any official versions of that information, where
                applicable, such as information provided by a candidate or
                government office.
              </div>
         
        </div>
        <div className="my-5">
        
              <div className={style.sectionHeading}>Other Websites </div>
              <div className={style.privacyText}>
                We provide links to other websites, but we can’t control what
                those sites say or do. In particular, websites to which we link
                may request your personal and/or financial information. We Care
                Texas is not responsible for other sites’ content, information
                collection practices, or the use of the information they
                collect. We Care Texas’s links to other websites do not
                constitute an endorsement of those sites or their content,
                owners, or posters. We Care Texas encourages third parties and
                individuals to link to I Stand With The Forgotten Women and content posted
                herein. Such links do not constitute an endorsement by We Care
                Texas of these third-party websites or their content, owners, or
                posters.
              </div>
         
        </div>
        <div className="my-5">
        
              <div className={style.sectionHeading}>Other Laws </div>
              <div className={style.privacyText}>
                Donations to charities (501(c)(3) organizations) and social
                welfare groups (501(c)(4) organizations) are not subject to any
                contributions limits. You may make unlimited donations to these
                groups on the site. We Care Texas cannot be responsible for your
                treatment of these donations on your tax returns. Charitable
                donations are tax-deductible for federal income tax purposes.
                Social welfare donations are not tax-deductible as charitable
                contributions. We will do our best to provide you with all
                relevant information regarding the tax treatment of donations to
                the entities on our site, such as what percentage of your
                donation might be used for lobbying activities (check your
                receipt). However, it is your responsibility to report your
                donations accurately as required and to pay your taxes. We
                recommend consulting your tax adviser or tax preparer if you
                have any questions. All intellectual property rights not
                specifically mentioned in these terms of use are reserved to We
                Care Texas.
              </div>
          
        </div>
        <div className="my-5">
       
              <div className={style.sectionHeading}>Refund Policy </div>
              <div className={style.privacyText}>
                In order to make our messages to you more relevant, we may use
                your personal information to create a profile of your interests
                and preferences. What this means is that we could use pieces of
                information you’ve either given us directly (such as your age or
                location) or your behavior (your donation history, including the
                amount, frequency, and method through which you give, or any
                campaign actions you’ve taken, including signing petitions) to
                create a profile. This not only gives us an idea of who our
                supporters are but also helps to make sure that when we get in
                touch with you or ask you to take an action on our behalf, it is
                done in a way that is relevant and tailored to you. This enables
                us to identify supporters who are likely to be able to help us
                either in a financial or non-financial capacity, and it’s also
                an effective way for us to maximize the use of our marketing
                budget, so we’re able to help further our cause with the
                resources we have available to us. We consider this one of our
                legitimate interests and as such have considered our position
                carefully to ensure that any activity is implemented in a fair
                and balanced way, taking into account your rights as an
                individual. It is your right to opt-out of your information
                being used for profiling, and there is information on how to do
                this at the end of this section.
              </div>
          
        </div>
        <div className="my-5">
         
              <div className={style.sectionHeading}>Screening </div>
              <div className={style.privacyText}>
                Additionally, as part of our legitimate interests, we have
                considered that screening is an effective way for us to ensure
                that we are contacting you with appropriate and relevant
                messages. We operate a number of different programs to nurture
                support and want to make sure that when we make a request of any
                of our supporters, it’s an appropriate one. As such, we do make
                use of third-party wealth screening companies who combine
                information we provide with publicly available information to
                help us determine which of our supporter programs any particular
                supporter is best suited for. While this does form a crucial
                part of our strategy to secure voluntary income, it is your
                right to be able to opt-out from your information being
                screened, and details of how to do this are at the end of this
                section.
              </div>
        
          
        </div>
        <div className="my-5">
         
              <div className={style.sectionHeading}>
                How We Use The Information We Collect From You{" "}
              </div>
              <div className={style.privacyText}>
                The main ways in which we use the information you provide to us
                is to complete and action or deliver a product or service to you
                that you have reasonably requested. This may include but is not
                limited to processing a donation (including claiming Gift Aid,
                where you have asked that we do this), delivering programs and
                training, running our memberships programs, and conducting
                research. We will also use your information to help provide you
                with goods and services that you’ve bought or requested from us.
                We may also use the information you have provided to get in
                touch with you about our work unless you have specifically told
                us not to. Please note that we will not send you emails or text
                messages about our work unless you have specifically asked us
                to.
              </div>
          
        </div>
        <div className="my-5">
     
              <div className={style.sectionHeading}>
                Publicly Available Information{" "}
              </div>
              <div className={style.privacyText}>
                As part of the work we do, we occasionally carry out research of
                our own to identify individuals in the public spotlight who may
                be interested in supporting I Stand With The Forgotten Women,
                and also to gain additional insight into current supporters of
                significant public standing. This practice, known as desk
                research, makes use of publicly available information through
                sources such as published rich lists, newspapers, and corporate
                websites, and aids us in identifying opportunities for new major
                donors, event sponsorship, corporate partnerships, event
                speakers, and media opportunities. As a precautionary measure in
                order to ensure our events are safe and effective spaces for
                speakers, facilitators, and participants to share knowledge and
                experiences, we might carry out due diligence on event
                participants. Members of our events team might complete a
                routine check on information that is available in the public
                domain and, in exceptional circumstances, reserve the right to
                refuse entry if necessary. If applicable, a full refund will be
                provided. We may also carry out desk research in the following
                areas: prospective organizations in the public, private and
                third sectors who may be interested in joining our workplace
                programs. From time to time we may contact prospective schools
                who may be interested in attending one of our program delivery
                sessions and in turn joining our education program.
              </div>
           
        </div>
        <div className="my-5">
        
              <div className={style.sectionHeading}>Cookies </div>
              <div className={style.privacyText}>
                Cookies are small pieces of code that are downloaded to your
                computer or mobile device when you visit a website, allowing us
                to recognize your device on subsequent visits. We use cookies to
                monitor the status of our website and ensure that it is running
                efficiently, and we also use cookies to remember your
                preferences. Cookies are anonymous and are not linked to any
                personal information.
              </div>
           
        </div>
        <div className="my-5">
       
              <div className={style.sectionHeading}>
                How To Get In Touch With Us{" "}
              </div>
              <div className={style.privacyText}>
                <p> You can get in touch with us in the following ways.</p>
                <p> By post:</p>
                <p>
                  {" "}
                  Supporter Care Team I Stand With The Forgotten Women .org
                </p>
                <p>700 Milam Street, Suite</p>
                <p>#1300 Houston, Texas 77002</p>
                <p>By email: info@IStandWithTheForgottenWomen .org</p>
              </div>
          
        </div>
        <div className="my-5">
         
              <div className={style.sectionHeading}>
                Updates To This Policy{" "}
              </div>
              <div className={style.privacyText}>
                <p>
                  We may update this policy from time to time. If we make any
                  material changes to this policy we will let you know, should
                  we have sufficient permissions to do so. We will at this time
                  also make it clear on our website that we have updated our
                  terms.
                </p>
                <p>
                  All refund requests are subject to the availability of the
                  contribution funds. Because We Care Texas forwards
                  contributions to the programs, causes, or events, We Care
                  Texas will only refund a contribution if we still have the
                  funds or can recover the funds from the recipient. For this
                  reason, it is of the utmost importance that you make your
                  request for a refund as soon after contributing as possible.
                </p>
                <p>
                  All refunds issued by We Care Texas will be credited back to
                  the card used to make the contribution. In all cases where we
                  are not able to accommodate a refund request, we will be happy
                  to refer you to the recipient of your contribution so that you
                  may request a refund directly from them.
                </p>
                <p>
                  Our state-of-the-art fraud detection software constantly
                  monitors the site for fraudulent activity using stolen cards.
                  When our software identifies a fraudulent transaction, the
                  contribution will be refunded automatically regardless of
                  whether a refund has been requested.
                </p>
                <p>
                  To request a refund, please contact us at info@I Stand With The Forgotten Women
                  and we will do our best to assist you.
                </p>
                <p>Cancellation policy</p>
                <p>
                  For your convenience, We Care Texas offers the ability to make
                  a recurring contribution to one or more recipients using a
                  stored payment method. When you sign up for a recurring
                  contribution, We Care Texas will charge the amount you
                  designated to your stored account once each month and forward
                  that contribution to the recipient you have chosen. All
                  payment information is stored securely with DonorBox, and we
                  will never change the amount, frequency, or recipient of your
                  recurring contribution. If you wish to cancel future instances
                  of a recurring contribution or change the payment method used
                  for future charges, you can do so by clicking on the
                  appropriate link in your original receipt, by looking up your
                  contribution on the DonorBox website, or by sending us an
                  email with your request.
                </p>
                <p>
                  DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY THIS SITE
                  IS PROVIDED BY WE CARE TEXAS ON AN “AS IS” AND “AS AVAILABLE”
                  BASIS. WE CARE TEXAS MAKES NO REPRESENTATIONS OR WARRANTIES OF
                  ANY KIND, EXPRESS OR IMPLIED, WHETHER BASED UPON THE NATURE OF
                  MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
                  OTHERWISE, AS TO THE OPERATION OF THIS SITE, OR THE ACCURACY,
                  TIMELINESS, OR COMPLETENESS OF INFORMATION, CONTENT,
                  MATERIALS, OR SERVICES INCLUDED ON THIS SITE. YOU EXPRESSLY
                  AGREE THAT YOUR USE OF THIS SITE AND ANY INFORMATION CONTAINED
                  HEREIN IS AT YOUR SOLE RISK.
                </p>
                <p>
                  WE CARE TEXAS WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND
                  ARISING FROM THE USE OF THIS SITE, INCLUDING, BUT NOT LIMITED
                  TO, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, AND CONSEQUENTIAL
                  DAMAGES.
                </p>
                <p>
                  CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
                  WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES.
                  IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
                  DISCLAIMERS, EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU,
                  AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
                </p>

                <p>
                Changes to Terms of Service
                </p>
                <p>
                As we develop this site we may need to update the terms described above. Changes will be posted here, of course.
                </p>
                <p>
                Questions? Email info@IStandWithTheForgottenWomen
                </p>
              </div>
        
        </div>
      </div>
    </div>
  );
};

export default TermOfService;














