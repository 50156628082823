import Modal from "react-bootstrap/Modal";
import ReactPlayer from "react-player/lazy";
import { fileBaseUrl } from "../../utils/api";

function VideoPlayer({ showVideoPlayer, setShowVideoPlayer, videoUrl,isdata }) {
  return (
    isdata ? (
    <Modal
      show={showVideoPlayer}
      // dialogClassName="modal-90w"
      // className={`iframeWidth
      // `}
      size="xl"
      onHide={() => {
        setShowVideoPlayer(false);
      }}
    >
      <ReactPlayer
        url={`${fileBaseUrl}${videoUrl}`}
        playing={true}
        loop={false}
        controls={true}
        muted={false}
        width="100%"
        height="75vh"
        // playsinline={true}
      />
    </Modal>
    ):(
      <Modal
      show={showVideoPlayer}
      // dialogClassName="modal-90w"
      // className={`iframeWidth
      // `}
      size="xl"
      onHide={() => {
        setShowVideoPlayer(false);
      }}
    >
      <ReactPlayer
        url={videoUrl}
        playing={true}
        loop={false}
        controls={true}
        muted={false}
        width="100%"
        height="75vh"
        // playsinline={true}
      />
    </Modal>
    )
  );
}

export default VideoPlayer;
