import React from "react";
import style from "./aboutus.module.css";
import { Col, Row } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
import iconpeople from '../../asserts/Iconpeople.svg';
import iconperson from '../../asserts/Iconperson.svg';
import iconearth from '../../asserts/Iconearth.svg';
import iconsun from '../../asserts/Iconsun.svg';
import iconheart from '../../asserts/Iconheart.svg';
import iconhand from '../../asserts/Iconhand.svg';
import iconflower from '../../asserts/Iconflower.svg';
import iconbook from '../../asserts/Iconbook.svg';

const EmpoweringAbout = () => {
  // const navigate = useNavigate();
  return (
    <div className={style.mainabout}>


      <div className="container">
        <h1>Abortion is not an act of empowerment.
          Rather, it is it an act of weakness driven by fear and desperation. We empower them when we provide  meaningful support that aligns with their core values.</h1>

        <div className={style.aboutstandbutton}>
          <a href="/donate-to-us/"><button> Stand With Us</button></a>
        </div>
        <h1 className="p-0">Being nurturers by nature, abortion forces an attempt to suppress their natural instincts to nurture and protect life.</h1>
        {/* <Row className={style.emptext}>
          <Col lg={4} xl={3} md={4} className="mb-4">
            <div className={style.abouticon}>
              <img src={iconperson} />
            </div>
            <h4>Get Informed</h4>
            <p >Understand all your options, including parenting, adoption, and abortion.<span> Research thoroughly and seek reliable sources.</span></p>
          </Col>
          <Col lg={4} xl={3} md={4} className="mb-4">
            <div className={style.abouticon}>
              <img src={iconearth} />
            </div>
            <h4>Consult</h4>
            <p>Understand all your options, including parenting, adoption, and abortion. Research thoroughly and seek reliable sources.</p>
          </Col>
          <Col lg={4} xl={3} md={4} className="mb-4">
            <div className={style.abouticon}>
              <img src={iconsun} />
            </div>
            <h4>Consider Support</h4>

            <p>Talk to trusted family members, friends, or partners about your feelings and concerns.<span> Reach out to someone you trust for a supportive conversation.</span></p>
          </Col>
          <Col lg={4} xl={3} md={4} className="mb-4">
            <div className={style.abouticon}>
              <img src={iconheart} />
            </div>
            <h4>Reflect Values</h4>

            <p>Consider your values, beliefs, and what feels suitable. Align your decision with your long-term goals.<span> Journal your thoughts and feelings to gain clarity.</span></p>
          </Col>

          <Col lg={4} xl={3} md={4} className="mb-4">
            <div className={style.abouticon}>
              <img src={iconhand}/>
            </div>
            <h4>Avoid Pressure</h4>
            <p>Ensure that your decision is your own. Don’t let others pressure you into making a choice that doesn’t feel right for you.<span> Set boundaries with those who may influence your decision.</span></p>
          </Col>
          <Col lg={4} xl={3} md={4} className="mb-4">
            <div className={style.abouticon}>
              <img src={iconflower} />
            </div>
            <h4>Take Time</h4>
            <p>Unless it’s a medical emergency, take the time you need to make your decision. Rushing can lead to regret or second-guessing.<span> Permit yourself to take the necessary time to decide.</span></p>
          </Col>
          <Col lg={4} xl={3} md={4} className="mb-4">
            <div className={style.abouticon}>
              <img src={iconpeople} />
            </div>
            <h4>Seek Support</h4>
            <p>Consider speaking with a mental health professional if you feel overwhelmed. They can provide support and help you process your emotions.<span> We can help you.</span></p>
          </Col>
          <Col lg={4} xl={3} md={4} className="mb-4">
            <div className={style.abouticon}>
              <img src={iconbook}></img>
            </div>
            <h4>Consider Future</h4>
            <p>Think about the long-term effects of each option on your life, including emotional, financial, and personal impacts.<span> Make a pros and cons list to visualize potential outcomes.</span></p>
          </Col>
        </Row> */}
        {/* <Row className={style.emptext}>
      
      </Row> */}
      </div>

    </div>
  );
};

export default EmpoweringAbout;
