
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  amount:null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.user = action.payload;
    },

    loginUserAction: (state, action) => {
      state.amount = action.payload;
    }
  }
});

export const { setUserDetails, loginUserAction } = userSlice.actions;

export const userReducer = userSlice.reducer;
