import React from "react";
import style from "./aboutus.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Churchesabout = () => {

  return (
 
    <div className={style.backcolor}>
        <div className={style.churchestext}>
        {/* <img className="w-100" src="/greatest.png" alt="story" /> */}
        <div  className="container">
        <div className={style.churchtextp}>
        <h1>Our Greatest Need: Churches</h1>
         <p>The relationship between the church and pregnancy resource centers can be likened to a marriage ordained in heaven, where each entity complements and supports the other in fulfilling a shared mission. Pregnancy resource centers often serve as vital extensions of the church’s ministry, providing practical support, resources, and compassionate care to individuals facing unplanned pregnancies and related challenges.</p>

            <p>Likewise, the church offers spiritual guidance, moral support, and a community of faith to individuals served by pregnancy resource centers. Together, they form a powerful partnership grounded in love, compassion, and a shared commitment to upholding the sanctity of human life and supporting individuals in need. Just as a marriage thrives on mutual respect, cooperation, and shared values, so too does the relationship between the church and pregnancy resource centers flourish when grounded in a shared vision of promoting life, dignity, and hope for all. Through their collaborative efforts, they can offer holistic support and care to individuals and families, embodying the transformative power of love and grace.</p>
            </div>
        </div>
        </div>
        </div>
  );
};

export default Churchesabout;
