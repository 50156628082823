

import React, { useEffect, useState } from "react";
import style from "./donation.module.css";
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  ProgressBar,
  Row,
  ToggleButton,
} from "react-bootstrap";
import { AiOutlinePlayCircle } from "react-icons/ai";
import {
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
  FaShareAlt,
} from "react-icons/fa";
import ReactPlayer from "react-player/lazy";
import { IoMdShare } from "react-icons/io";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import VideoPlayer from "../../modals/videoPlayer/VideoPlayer";
import { Country, State } from "country-state-city";
import { fileBaseUrl } from "../../utils/api";
import {
  oneTimePayment,
  createSubscription,
  getRecentTransaction,
} from "../../apis/paymentApis";
import { useDispatch } from "react-redux";
import { getToken } from "../../utils/api";
import { toast } from "react-toastify";
import TransactionStatus from "../../modals/transaction/TransactionStatu";
import { getCenterById } from "../../apis/CenterApis";
import * as yup from "yup";
import { getTotalTransactionandPercentage } from "../../apis/paymentApis";
import useGetUser from "../../customHook/useGetUser";

const Adminpayment = () => {
  const token = getToken();
  const [searchParams] = useSearchParams();
  useGetUser();
  // const [radioValue, setRadioValue] = useState("1");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [centerData, setCenterData] = useState(null);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [isdata,setIsData]=useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [showTextArea, setShowTextArea] = useState(false);
  // const [amount, setAmount] = useState();
  const [showTransactionStatus, setShowTransactionStaus] = useState(false);
  const [donationList, setDonationList] = useState(null);
  const [centerDetail, setCenterDetail] = useState();
  const [ usercomment, setUserComment] = useState("");

  const schema = yup.object().shape({
    amount: yup
      .number()
      .typeError("Amount must be a number")
      .positive("Amount must be a positive number")
      .required("Amount is required"),
  });

 const [amount, setSelectedAmount] = useState(""); // State for the selected amount radio button
  const [selectedFrequency, setSelectedFrequency] = useState("month"); // State for the selected frequency radio button

  // Existing functions...
  const [showInput, setShowInput] = useState(false);

  const handleLinkClick = (e) => {
    e.preventDefault();
    setShowInput((prevShowInput) => !prevShowInput);
  };


  const handleAmountChange = (e) => {
    setSelectedAmount(e.target.value); // Update the selected amount
  };

  const handleFrequencyChange = (e) => {
    setSelectedFrequency(e.target.value); // Update the selected frequency
  };
  const handleDonate = () => {
    if (!amount) {
      toast.error("Please select a  amount for proceed the payment"); // Show error toast if no amount is selected
      return;
    }
        localStorage.setItem('amount', amount);
        localStorage.setItem('frequency', selectedFrequency);
        navigate("/information/")
  
  };


 
return (
    <div className={style.donationcontainer}>
      <div className="container">
        <div className={style.inforcol}>
          <Row className="align-items-center">
          <Col md={12} xl={7} lg={12}>
              <div className="p-3">
                <div className={style.paymentForm}>
                  <div className={style.paymentHead}>Choose A Donation Amount</div>
                  <div className={style.donationpayment}>
                    

                 <div className={
                        amount === "15" ? style.selectedLabel :style.donateinputcontainer
                      }>
                    <label
                
                    >
                      <div className={style.innerlabel}>
                      <div className={style.smallscreeninput}>
                        <input
                          type="radio"
                          name="amount"
                          value="15"
                          checked={amount === "15"}
                          onChange={handleAmountChange}
                        />
                    
                        <span>$15</span>
                        </div>
                        <div>
                        <p>
                          {" "}
                          Covers 80% of one hour of a case worker’s salary to
                          assess a client’s needs.
                        
                        </p>
                        </div>
                     
                      </div>
                    </label>
                  </div>

                  <div className={amount === "25" ? style.selectedLabel : style.donateinputcontainer}>
                    <label
                    >
                      <div className={style.innerlabel}>
                      <div className={style.smallscreeninput}>
                        <input
                          type="radio"
                          name="amount"
                          value="25"
                          checked={amount === "25"}
                          onChange={handleAmountChange}
                        />
                   
                        <span>$25</span>
                        </div>
                        <div>
                        <p>
                          {" "}
                          Helps cover childbirth and parenting classes,
                          relationships training, material needs.
                        </p>
                        </div>
                      </div>
                    </label>
                  </div>

                  <div  className={amount === "50" ? style.selectedLabel : style.donateinputcontainer}>
                    <label
                    >
                      <div className={style.innerlabel}>
                      <div className={style.smallscreeninput}>
                        <input
                          type="radio"
                          name="amount"
                          value="50"
                          checked={amount === "50"}
                          onChange={handleAmountChange}
                        />
                     
                        <span>$50</span>
                        </div>
                      
                        <p>
                          {" "}
                          Covers the cost of onsite medical ultrasound
                          sonographer’s salary to verify pregnancy and prenatal
                          counseling.
                        </p>
                     
                      </div>
                    </label>
                  </div>

                   
                    {/* <Form.Group className="my-3" controlId="formBasicCheckbox">
                      <Form.Check
                        className={style.donationcomment}
                        type="checkbox"
                        label="Write us a comment"
                        aria-label="usercomment"
                        value={usercomment}
                        onClick={() => setShowTextArea(!showTextArea)}
                      />
                      {showTextArea && (
                        <textarea
                          className={`${style.commentBox}`}
                          type="textarea"
                          placeholder="Your Comment"
                          aria-label="usercomment"
                          value={usercomment}
                          onChange={(e) => setUserComment(e.target.value)} // Update comment state
                    
                        />
                      )}
                    </Form.Group> */}
                    <div className={style.amountdonation} >
                  <div className={style.labellink}>
        <a href="#" onClick={handleLinkClick}>
          Enter a custom donation amount
        </a>
      </div>
      {showInput && (
        <div className={style.customAmountInput}>
      
          <input
            type="text"
            value={amount}
            placeholder="Enter custom amount"
            onChange={(e) => setSelectedAmount(e.target.value)}
          />
        </div>
      )}

                  </div>
                  <h3>Choose a donation frequency</h3>
                  <div className={style.donateradio}>
                    <div className={selectedFrequency === "1" ? style.selectedLabel2:style.labeldonate}>
                      <div className={style.donationlabel}>
                      <label
                          className={
                            style.labelcontent
                          }>
                          <input
                            type="radio"
                            name="frequency"
                            value="1"
                            checked={selectedFrequency === "1"}
                            onChange={handleFrequencyChange}
                          />

                          <span>OneTime</span>
                        </label>
                      </div>
                    </div>

                    <div className={selectedFrequency === "month" ? style.selectedLabel2:style.labeldonate2}>
                      <div className={ style.donationlabel}>
                        <label
                          className={
                            style.labelcontent
                          }>
                          <input
                            type="radio"
                            name="frequency"
                            value="month"
                            checked={selectedFrequency === "month"}
                            onChange={handleFrequencyChange}
                          />

                          <span>Monthly</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className={style.donationbuttoncontainer}>
                    {/* <div className={style.smallscreena}>
                    <a href="#">cancel</a>
                    </div> */}
                    <div className={style.smallscreenabutton}>
                    <button onClick={handleDonate}>Go to Checkout</button>
                    </div>
                  </div>
                 
                  </div>
                
                </div>
              </div>
            </Col>

            <Col md={12} xl={5} lg={5} xs={12} >
              <div className={style.donatethank}>
                <h1>
                  Thank You <br></br>
                  for proving they’re not forgotten
                </h1>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Adminpayment;