import React, { useState } from "react";
import style from "./speaker.module.css";
import { Col, Row, Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import { bookAppoinment } from "../../apis/authApis";

import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";

const SpeakerRequest = () => {
  const dispatch=useDispatch()
  const [disableButton, setDisableButton] = useState(false);
  const [startDate, setStartDate] = useState();

  return (
    <div>
      <div className={style.speaker_banner}>
        <Row className="m-0  p-0">
          <Col md={{ span: 10, offset: 2 }}>
            <div className="pt-5">
              <div className="text-start mt-5">
                <img src="/mainlogo.svg" alt="logo" width={200} />
              </div>
              <div className={style.banner_hd}>
                Request Mike Goss
                <br /> to Speak at Your Event!
              </div>
              <div className="mt-3">
                {" "}
              <a href="#speakerform">  <button className="bluebtn">Book Now</button></a>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={style.speaker_row}>
        <div className="my-5 ">
          <div className={style.speaker_heading}>
            {" "}
            Thousands have been inspired to embrace abundant life through one of
            Mike Goss presentations. Utilizing Scripture, humor, and the story
            of his life, he communicates life-changing truth in an unforgettable
            way.
            <br />{" "}
            <div className="" style={{ fontSize: "20px" }}>
              {" "}
              See what others have to say about Mike Goss Presentation:
            </div>
          </div>
        </div>
        <div className="my-5">
          <Row className="">
            <Col className="m-auto" md={6}>
              <div className={style.speaker_heading}>
                Ashley Baldwin, Choices Pregnancy Center{" "}
              </div>
              <div className={style.speaker_text}>
                “Mike Goss is a seasoned speaker who not only provides great
                vision and insight into the life issue but weaves in his own
                redemptive story. He brings a much needed message focusing not
                on being pro-life, but being pro-abundant life. He also brings
                in the male’s perspective and this was definitely helpful in
                reaching our audience. His vision went right along with the new
                vision of our center and our banquet was a smashing success. So
                much of the feedback that I received was on Mike Goss and how
                much our supporters enjoyed hearing him speak. I would HIGHLY
                recommend having Mike Goss as your next banquet speaker.”
              </div>
            </Col>
            <Col className="" md={6}>
              <div className="text-md-end text-center">
                <img className="w-75" src="/speaker/speaker2.png" alt="story" />
              </div>
            </Col>
          </Row>
        </div>
        <div className="my-5">
          <Row className="">
            <Col className="" md={6}>
              <div>
                <img
                  className="w-100"
                  src="/speaker/speaker1.webp"
                  alt="story"
                />
              </div>
            </Col>
            <Col className="m-auto" md={6}>
              <div className={style.speaker_heading}>
                Frank Burger, Valley Pregnancy Center Director{" "}
              </div>
              <div className={style.speaker_text}>
                "Of course, the success of the dinner is not just based on how
                folks felt when they left that evening or from the feedback we
                received. The success of the banquet is really measured in the
                amount of donations received. Our banquet had been raising just
                under $175,000 for the last few years. Our prayer for 2015 was
                to increase our giving to $200,000 in order to fund another
                abstinence presenter for our school program. When the dust all
                settled and our tabulations were complete, God had blessed us,
                through Mike Goss, to raise over $200,000 for this ministry."
              </div>
            </Col>
          </Row>
        </div>

        <div className="my-5 ">
          <div className={style.speaker_heading}>
            Mike Goss is available to speak at your next pregnancy center event
            or banquet, church event, conference, or fundraiser. Book now using
            the form below.
          </div>

          <div className={style.formBox} id="speakerform">
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                requestEventDate: "",
                careNetAffiliate: "",
                eventContactName: "",
                eventContactEmail: "",
                eventContactPhone: "",
                eventOnsiteContactName: "",
                eventOnsiteContactPhone: "",
                typeOfEvent: "",
                eventLocation: "",
                eventStreetAddress: "",
                zipCode: "",
                nearestAirportToEvent: "",
                transportTimeBetweenAirportAndHotel: "",
                transportTimeBetweenHotelAndVenue: "",
                loadgingForSpeaker: "",
                startEndTimeEvent: "",
                lengthOfPresentation: "",
                topicOfPresentation: "",
                numberOfAttendees: "",
                audinceProfile: "",
                accomodateBookSigning: "",
                provideLaptop: "",
                providePrivacyMonitor: "",
                presentationRecorded: "",
                audioOrVideo: "",
                honarariumTerms: "",
                digitalSignature: "",
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string().required("Field is required"),
                lastName: Yup.string().max(255).required("Field is required"),
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Field is required"),
                requestEventDate: Yup.string().required("Field is required"),
                careNetAffiliate: Yup.string().required("Field is required"),
                eventContactName: Yup.string().required("Field is required"),
                eventContactEmail: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Field is required"),
                eventContactPhone: Yup.string().required("Field is required"),
                eventOnsiteContactName:
                  Yup.string().required("Field is required"),
                eventOnsiteContactPhone:
                  Yup.string().required("Field is required"),
                typeOfEvent: Yup.string().required("Field is required"),
                eventLocation: Yup.string().required("Field is required"),
                eventStreetAddress: Yup.string().required("Field is required"),
                zipCode: Yup.string().required("Field is required"),
                nearestAirportToEvent:
                  Yup.string().required("Field is required"),
                startEndTimeEvent: Yup.string().required("Field is required"),
                lengthOfPresentation:
                  Yup.string().required("Field is required"),
                topicOfPresentation: Yup.string().required("Field is required"),
                numberOfAttendees: Yup.string().required("Field is required"),
                audinceProfile: Yup.string().required("Field is required"),
                accomodateBookSigning:
                  Yup.string().required("Field is required"),
                provideLaptop: Yup.string().required("Field is required"),
                providePrivacyMonitor:
                  Yup.string().required("Field is required"),
                audioOrVideo: Yup.string().required("Field is required"),
                digitalSignature: Yup.string().required("Field is required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting, resetForm }
              ) => {
                setDisableButton(true);
                try {
                  setStatus({ success: false });
                  setSubmitting(false);
                    dispatch(bookAppoinment(values)).then((res) => {
                      if (res) {
                        resetForm();
                        // setShowSucessModal(true);
                        setDisableButton(false);
                      }
                      setDisableButton(false);
                    });
                } catch (err) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setSubmitting(false);
                  //   setDisableButton(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="m-0 p-0">
                    <Col md={6}>
                      <Form.Group className="mb-4" controlId="formfirstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          type="text"
                          className={
                            touched.firstName && errors.firstName
                              ? "is-invalid"
                              : ""
                          }
                          value={values.firstName}
                          name="firstName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.firstName && errors.firstName)}
                        />
                        {touched.firstName && errors.firstName && (
                          <Form.Text className=" text-danger">
                            {errors.firstName}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                   
                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formBasicLastName"
                      >
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          type="text"
                          className={
                            touched.lastName && errors.lastName
                              ? "is-invalid"
                              : ""
                          }
                          value={values.lastName}
                          name="lastName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.lastName && errors.lastName)}
                        />
                        {touched.lastName && errors.lastName && (
                          <Form.Text className=" text-danger">
                            {errors.lastName}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-4" controlId="formBasicEmail">
                        <Form.Label>Email </Form.Label>
                        <Form.Control
                          className={
                            touched.email && errors.email ? "is-invalid" : ""
                          }
                          type="email"
                          value={values.email}
                          name="email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.email && errors.email)}
                        />
                        {touched.email && errors.email && (
                          <Form.Text className=" text-danger">
                            {errors.email}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formBasicrequestEventDate"
                      >
                        <Form.Label>Request Event Date</Form.Label>
                        <div>
                          <DatePicker
                           className={`
                            ${style.calendarInput} 
                            ${touched.requestEventDate && errors.requestEventDate ? "is-invalid" : ""}
                          `}
                            selected={startDate}
                            onChange={(date) => {setStartDate(date)
                              // handleChange(date);
                              setFieldValue("requestEventDate",date );
                            }
                            }
                            onBlur={handleBlur}
                            error={Boolean(
                              touched.requestEventDate && errors.requestEventDate
                            )}
                          />
                        </div>
                        {/* <Form.Control
                        
                          type={"text"}
                          value={values.requestEventDate}
                          name="requestEventDate"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.requestEventDate && errors.requestEventDate
                          )}
                        /> */}

                        {touched.requestEventDate &&
                          errors.requestEventDate && (
                            <Form.Text className=" text-danger">
                              {errors.requestEventDate}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-4" controlId="formBasicCountry">
                        <Form.Label>Care Net Affiliate?</Form.Label>
                        <Form.Select
                          // onChange={handlecareNetAffiliateChange}
                          // value={selectedcareNetAffiliate}
                          className={
                            touched.careNetAffiliate && errors.careNetAffiliate
                              ? "is-invalid"
                              : ""
                          }
                          value={values.careNetAffiliate}
                          name="careNetAffiliate"
                          onBlur={handleBlur}
                          // onChange={handleChange}
                          onChange={(e) => {
                            handleChange(e);
                            // handlecareNetAffiliateChange(e);
                            setFieldValue("careNetAffiliate", e.target.value);
                          }}
                          error={Boolean(
                            touched.careNetAffiliate && errors.careNetAffiliate
                          )}
                        >
                          <option value="" disabled>
                            {/* Please Select */}
                          </option>
                          <option key={5} value="Yes">
                            Yes
                          </option>
                          <option key={6} value="No">
                            No
                          </option>
                        </Form.Select>
                        {touched.careNetAffiliate &&
                          errors.careNetAffiliate && (
                            <Form.Text className=" text-danger">
                              {errors.careNetAffiliate}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formBasiceventcontactName"
                      >
                        <Form.Label>Event Contact Name</Form.Label>
                        <Form.Control
                          className={
                            touched.eventContactName && errors.eventContactName
                              ? "is-invalid"
                              : ""
                          }
                          type={"text"}
                          value={values.eventContactName}
                          name="eventContactName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.eventContactName && errors.eventContactName
                          )}
                        />

                        {touched.eventContactName &&
                          errors.eventContactName && (
                            <Form.Text className=" text-danger">
                              {errors.eventContactName}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formBasiceventcontactEmail"
                      >
                        <Form.Label>Event Contact Email</Form.Label>
                        <Form.Control
                          className={
                            touched.eventContactEmail &&
                            errors.eventContactEmail
                              ? "is-invalid"
                              : ""
                          }
                          type={"email"}
                          value={values.eventContactEmail}
                          name="eventContactEmail"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.eventContactEmail &&
                              errors.eventContactEmail
                          )}
                        />

                        {touched.eventContactEmail &&
                          errors.eventContactEmail && (
                            <Form.Text className=" text-danger">
                              {errors.eventContactEmail}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formEventContactPhone"
                      >
                        <Form.Label>Event Contact Phone (Mobile)</Form.Label>
                        <PhoneInput
                          name="eventContactPhone"
                          eventContactPhone={"us"}
                          enableSearch={true}
                          country={"us"}
                          inputClass={` w-100
                          ${
                            touched.eventContactPhone &&
                            errors.eventContactPhone
                              ? "is-invalid"
                              : ""
                          }`}
                          value={values.eventContactPhone}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue("eventContactPhone", e);
                            handleChange(e);
                          }}
                          error={Boolean(
                            touched.eventContactPhone &&
                              errors.eventContactPhone
                          )}
                        />
                        {touched.eventContactPhone &&
                          errors.eventContactPhone && (
                            <Form.Text className=" text-danger">
                              {errors.eventContactPhone}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formBasiceventonsitecontactName"
                      >
                        <Form.Label>Event Onsite Contact Name</Form.Label>
                        <Form.Control
                          className={
                            touched.eventOnsiteContactName &&
                            errors.eventOnsiteContactName
                              ? "is-invalid"
                              : ""
                          }
                          type={"text"}
                          value={values.eventOnsiteContactName}
                          name="eventOnsiteContactName"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.eventOnsiteContactName &&
                              errors.eventOnsiteContactName
                          )}
                        />

                        {touched.eventOnsiteContactName &&
                          errors.eventOnsiteContactName && (
                            <Form.Text className=" text-danger">
                              {errors.eventOnsiteContactName}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-4" controlId="formPhone">
                        <Form.Label>
                          Event Onsite Contact Phone (Mobile)
                        </Form.Label>
                        <PhoneInput
                          name="eventOnsiteContactPhone"
                          careNetAffiliate={"us"}
                          country={"us"}
                          enableSearch={true}
                          inputClass={` w-100
                          ${
                            touched.eventOnsiteContactPhone &&
                            errors.eventOnsiteContactPhone
                              ? "is-invalid"
                              : ""
                          }`}
                          value={values.eventOnsiteContactPhone}
                          onBlur={handleBlur}
                          onChange={(e) => {
                            setFieldValue("eventOnsiteContactPhone", e);
                            handleChange(e);
                          }}
                          error={Boolean(
                            touched.eventOnsiteContactPhone &&
                              errors.eventOnsiteContactPhone
                          )}
                        />
                        {touched.eventOnsiteContactPhone &&
                          errors.eventOnsiteContactPhone && (
                            <Form.Text className=" text-danger">
                              {errors.eventOnsiteContactPhone}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-4" controlId="formBasicstate">
                        <Form.Label>Type Of Event </Form.Label>
                        <Form.Select
                          className={
                            touched.typeOfEvent && errors.typeOfEvent
                              ? "is-invalid"
                              : ""
                          }
                          value={values.typeOfEvent}
                          name="typeOfEvent"
                          onBlur={handleBlur}
                          onChange={(e) => {
                            handleChange(e);
                            setFieldValue("typeOfEvent", e.target.value);
                          }}
                          error={Boolean(
                            touched.typeOfEvent && errors.typeOfEvent
                          )}
                        >
                          <option value="" disabled>
                            {/* Select Type Of Event */}
                          </option>
                          <option value={"Keynote"}>Keynote</option>
                          <option value={"Workshop"}>Workshop</option>
                          <option value={"Pannel"}>Pannel</option>
                          <option value={"Other"}>Other?</option>
                        </Form.Select>
                        {touched.typeOfEvent && errors.typeOfEvent && (
                          <Form.Text className=" text-danger">
                            {errors.typeOfEvent}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-4" controlId="formeventLocation">
                        <Form.Label> Event Location*</Form.Label>
                        <Form.Control
                          type="text"
                          className={
                            touched.eventLocation && errors.eventLocation
                              ? "is-invalid"
                              : ""
                          }
                          value={values.eventLocation}
                          name="eventLocation"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.eventLocation && errors.eventLocation)}
                        />
                        {touched.eventLocation && errors.eventLocation && (
                          <Form.Text className=" text-danger">
                            {errors.eventLocation}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-4" controlId="formAddress">
                        <Form.Label>Event Street Address</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={1}
                          className={
                            touched.eventStreetAddress &&
                            errors.eventStreetAddress
                              ? "is-invalid"
                              : ""
                          }
                          value={values.eventStreetAddress}
                          name="eventStreetAddress"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.eventStreetAddress &&
                              errors.eventStreetAddress
                          )}
                        />
                        {touched.eventStreetAddress &&
                          errors.eventStreetAddress && (
                            <Form.Text className=" text-danger">
                              {errors.eventStreetAddress}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group className="mb-4" controlId="formZipCode">
                        <Form.Label> Event City/St/Zip</Form.Label>
                        <Form.Control
                          type="text"
                          className={
                            touched.zipCode && errors.zipCode
                              ? "is-invalid"
                              : ""
                          }
                          value={values.zipCode}
                          name="zipCode"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.zipCode && errors.zipCode)}
                        />
                        {touched.zipCode && errors.zipCode && (
                          <Form.Text className=" text-danger">
                            {errors.zipCode}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formnearestAirpotyToEvent"
                      >
                        <Form.Label>Nearest Airport To Event</Form.Label>
                        <Form.Control
                          type="text"
                          className={
                            touched.nearestAirportToEvent &&
                            errors.nearestAirportToEvent
                              ? "is-invalid"
                              : ""
                          }
                          value={values.nearestAirportToEvent}
                          name="nearestAirportToEvent"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.nearestAirportToEvent &&
                              errors.nearestAirportToEvent
                          )}
                        />
                        {touched.nearestAirportToEvent &&
                          errors.nearestAirportToEvent && (
                            <Form.Text className=" text-danger">
                              {errors.nearestAirportToEvent}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formnetranportEvent"
                      >
                        <Form.Label>
                          Transport Time between Airport and Hotel
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={
                            touched.transportTimeBetweenAirportAndHotel &&
                            errors.transportTimeBetweenAirportAndHotel
                              ? "is-invalid"
                              : ""
                          }
                          value={values.transportTimeBetweenAirportAndHotel}
                          name="transportTimeBetweenAirportAndHotel"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.transportTimeBetweenAirportAndHotel &&
                              errors.transportTimeBetweenAirportAndHotel
                          )}
                        />
                        {touched.transportTimeBetweenAirportAndHotel &&
                          errors.transportTimeBetweenAirportAndHotel && (
                            <Form.Text className=" text-danger">
                              {errors.transportTimeBetweenAirportAndHotel}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formnetranportEvent"
                      >
                        <Form.Label>
                          Transport Time between Hotel and Venue
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={
                            touched.transportTimeBetweenHotelAndVenue &&
                            errors.transportTimeBetweenHotelAndVenue
                              ? "is-invalid"
                              : ""
                          }
                          value={values.transportTimeBetweenHotelAndVenue}
                          name="transportTimeBetweenHotelAndVenue"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.transportTimeBetweenHotelAndVenue &&
                              errors.transportTimeBetweenHotelAndVenue
                          )}
                        />
                        {touched.transportTimeBetweenHotelAndVenue &&
                          errors.transportTimeBetweenHotelAndVenue && (
                            <Form.Text className=" text-danger">
                              {errors.transportTimeBetweenHotelAndVenue}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formnelodgingEvent"
                      >
                        <Form.Label>Lodging for Speaker (If Known)</Form.Label>
                        <Form.Control
                          type="text"
                          className={
                            touched.loadgingForSpeaker &&
                            errors.loadgingForSpeaker
                              ? "is-invalid"
                              : ""
                          }
                          value={values.loadgingForSpeaker}
                          name="loadgingForSpeaker"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.loadgingForSpeaker &&
                              errors.loadgingForSpeaker
                          )}
                        />
                        {touched.loadgingForSpeaker &&
                          errors.loadgingForSpeaker && (
                            <Form.Text className=" text-danger">
                              {errors.loadgingForSpeaker}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formnelodgingEvent"
                      >
                        <Form.Label>Start/End Time for Event</Form.Label>
                        <Form.Control
                          type="text"
                          className={
                            touched.startEndTimeEvent &&
                            errors.startEndTimeEvent
                              ? "is-invalid"
                              : ""
                          }
                          value={values.startEndTimeEvent}
                          name="startEndTimeEvent"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.startEndTimeEvent &&
                              errors.startEndTimeEvent
                          )}
                        />
                        {touched.startEndTimeEvent &&
                          errors.startEndTimeEvent && (
                            <Form.Text className=" text-danger">
                              {errors.startEndTimeEvent}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formnelodgingEvent"
                      >
                        <Form.Label>
                          Length of Presentation (minimum 40min)
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={
                            touched.lengthOfPresentation &&
                            errors.lengthOfPresentation
                              ? "is-invalid"
                              : ""
                          }
                          value={values.lengthOfPresentation}
                          name="lengthOfPresentation"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.lengthOfPresentation &&
                              errors.lengthOfPresentation
                          )}
                        />
                        {touched.lengthOfPresentation &&
                          errors.lengthOfPresentation && (
                            <Form.Text className=" text-danger">
                              {errors.lengthOfPresentation}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formnelodgingEvent"
                      >
                        <Form.Label>Number of Attendees</Form.Label>
                        <Form.Control
                          type="text"
                          className={
                            touched.numberOfAttendees &&
                            errors.numberOfAttendees
                              ? "is-invalid"
                              : ""
                          }
                          value={values.numberOfAttendees}
                          name="numberOfAttendees"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.numberOfAttendees &&
                              errors.numberOfAttendees
                          )}
                        />
                        {touched.numberOfAttendees &&
                          errors.numberOfAttendees && (
                            <Form.Text className=" text-danger">
                              {errors.numberOfAttendees}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formnelodgingEvent"
                      >
                        <Form.Label>Audience Profile</Form.Label>
                        <Form.Control
                          type="text"
                          className={
                            touched.audinceProfile && errors.audinceProfile
                              ? "is-invalid"
                              : ""
                          }
                          value={values.audinceProfile}
                          name="audinceProfile"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.audinceProfile && errors.audinceProfile
                          )}
                        />
                        {touched.audinceProfile && errors.audinceProfile && (
                          <Form.Text className=" text-danger">
                            {errors.audinceProfile}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formnelodgingEvent"
                      >
                        <Form.Label>Topic of Presentationt</Form.Label>
                        <Form.Control
                          type="text"
                          className={
                            touched.topicOfPresentation &&
                            errors.topicOfPresentation
                              ? "is-invalid"
                              : ""
                          }
                          value={values.topicOfPresentation}
                          name="topicOfPresentation"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.topicOfPresentation &&
                              errors.topicOfPresentation
                          )}
                        />
                        {touched.topicOfPresentation &&
                          errors.topicOfPresentation && (
                            <Form.Text className=" text-danger">
                              {errors.topicOfPresentation}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formnelodgingEvent"
                      >
                        <Form.Label>
                          Can you accommodate a book signing following the event
                          program?
                        </Form.Label>
                        <Form.Check
                               className={style.speakerradio}
                          label="Yes"
                          name="accomodateBookSigning"
                          type={"radio"}
                          id={`inline-radio-1`}
                          value="Yes"
                          onChange={handleChange}
                          checked={values.accomodateBookSigning ==="Yes"}
                        />
                        <Form.Check
                        className={style.speakerradio}
                          label="No"
                          name="accomodateBookSigning"
                          type={"radio"}
                          id={`inline-radio-2`}
                          value="No"
                          onChange={handleChange}
                          checked={values.accomodateBookSigning ==="No"}
                        />
                        {touched.accomodateBookSigning &&
                          errors.accomodateBookSigning && (
                            <Form.Text className=" text-danger">
                              {errors.accomodateBookSigning}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formnelodgingEvent"
                      >
                        <Form.Label>
                          Can you provide a laptop and small table near podium
                          for Mike Goss to advance presentation slides?
                        </Form.Label>
                      
                        <Form.Check
                          className={style.speakerradio}
                          label="Yes"
                          name="provideLaptop"
                          type={"radio"}
                          id={`inline-radio-1`}
                          value="Yes"
                          onChange={handleChange}
                          checked={values.provideLaptop ==="Yes"}
                        />
                        <Form.Check
                         className={style.speakerradio}
                          label="No"
                          name="provideLaptop"
                          type={"radio"}
                          id={`inline-radio-2`}
                          value="NO"
                          onChange={handleChange}
                          checked={values.provideLaptop ==="NO"}

                        />

                        {touched.provideLaptop && errors.provideLaptop && (
                          <Form.Text className=" text-danger">
                            {errors.provideLaptop}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formnelodgingEvent"
                      >
                        <Form.Label>
                          Can you provide a privacy monitor on platform or stage
                          for Mr. Warren to see his slides during the
                          presentation?
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className={
                            touched.providePrivacyMonitor &&
                            errors.providePrivacyMonitor
                              ? "is-invalid"
                              : ""
                          }
                          value={values.providePrivacyMonitor}
                          name="providePrivacyMonitor"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.providePrivacyMonitor &&
                              errors.providePrivacyMonitor
                          )}
                        />
                        {touched.providePrivacyMonitor &&
                          errors.providePrivacyMonitor && (
                            <Form.Text className=" text-danger">
                              {errors.providePrivacyMonitor}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formnelodgingEvent"
                      >
                        <Form.Label>
                          Will the presentation be recorded?
                        </Form.Label>
                        <Form.Check
                               className={style.speakerradio}
                          label="Yes"
                          name="presentationRecorded"
                          type={"radio"}
                          id={`inline-radio-1`}
                          value="Yes"
                          onChange={handleChange}
                          checked={values.presentationRecorded ==="Yes"}

                        />
                        <Form.Check
                               className={style.speakerradio}
                          label="No"
                          name="presentationRecorded"
                          type={"radio"}
                          id={`inline-radio-2`}
                          value="No"
                          onChange={handleChange}
                          checked={values.presentationRecorded ==="No"}

                        />
                      
                        {touched.presentationRecorded &&
                          errors.presentationRecorded && (
                            <Form.Text className=" text-danger">
                              {errors.presentationRecorded}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formnelodgingEvent"
                      >
                        <Form.Label>
                          Can you meet the Audio/Visual Requirements?
                        </Form.Label>
                        <br />
                        <Form.Text className="" style={{ lineHeight: "12px" }}>
                          Audio and video capabilities, wireless lavaliere
                          microphone, projection screen and 32 inch privacy
                          monitor on platform which will also be a feed from the
                          big screen, thus mirroring the laptop view. A small
                          cocktail table for laptop (center provided laptop)
                          with remote advancer near podium to advance PowerPoint
                          slides.
                        </Form.Text>
                       
                        <Form.Check
                               className={style.speakerradio}
                          label="Yes"
                          name="audioOrVideo"
                          type={"radio"}
                          id={`inline-radio-1`}
                          value="Yes"
                          onChange={handleChange}
                          checked={values.audioOrVideo ==="Yes"}

                        />
                        <Form.Check
                               className={style.speakerradio}
                          label="No"
                          name="audioOrVideo"
                          type={"radio"}
                          id={`inline-radio-2`}
                          value="No"
                          onChange={handleChange}
                          checked={values.audioOrVideo ==="No"}

                        />
                        {touched.audioOrVideo && errors.audioOrVideo && (
                          <Form.Text className=" text-danger">
                            {errors.audioOrVideo}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formnelodgingEvent"
                      >
                        <Form.Label>
                          Do you Agree To Honorarium Terms? (For Pregnancy
                          Centers Only: $5,200 Non-Affiliate: $5,700) *hosts are
                          responsible for lodging.
                        </Form.Label>
                        <Form.Check
                               className={style.speakerradio}
                          label="Yes"
                          name="honarariumTerms"
                          type={"radio"}
                          id={`inline-radio-1`}
                          value="Yes"
                          onChange={handleChange}
                          checked={values.honarariumTerms ==="Yes"}

                        />
                        <Form.Check
                               className={style.speakerradio}
                          label="No"
                          name="honarariumTerms"
                          type={"radio"}
                          id={`inline-radio-2`}
                          value="No"
                          onChange={handleChange}
                          checked={values.honarariumTerms ==="No"}

                        />
                        {touched.honarariumTerms && errors.honarariumTerms && (
                          <Form.Text className=" text-danger">
                            {errors.honarariumTerms}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group
                        className="mb-4"
                        controlId="formnelodgingEvent"
                      >
                        <Form.Label>Digital Signature</Form.Label>
                        <Form.Control
                          type="text"
                          className={
                            touched.digitalSignature && errors.digitalSignature
                              ? "is-invalid"
                              : ""
                          }
                          value={values.digitalSignature}
                          name="digitalSignature"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.digitalSignature && errors.digitalSignature
                          )}
                        />
                        <Form.Text className="">
                          Please type your full legal name as a digital
                          signature to complete your speaker request
                        </Form.Text>
                        <br />
                        {touched.digitalSignature &&
                          errors.digitalSignature && (
                            <Form.Text className=" text-danger">
                              {errors.digitalSignature}
                            </Form.Text>
                          )}
                      </Form.Group>
                    </Col>
                    <Col md={{ span: 6, offset: 3 }}>
                      <Button
                        className="w-100 authBlubtn"
                        variant="primary"
                        type="submit"
                        disabled={disableButton}
                      >
                        Book Now
                        {disableButton && (
                          <Spinner
                            className="ms-3"
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}
                      </Button>
                    </Col>
                  </Row>
                  {/* <Row> */}
                  {/* </Row> */}
                </Form>
              )}
            </Formik>
            <div className="text-center mt-3 mb-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpeakerRequest;
