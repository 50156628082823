import axios from 'axios';
import { toast } from 'react-toastify';
import { setUserDetails } from '../redux/reducers/user';
// import { baseUrl,getHeader } from 'utils/api';
import { baseUrl,getHeader } from '../utils/api';


export const getUserDetails =()=> async (dispatch) => {
    try {
        const header=  await getHeader()
      const response = await axios.get(`${baseUrl}/users/user`,{
        headers:header });
      if(response?.status===200 ||response?.status===201){
        dispatch(setUserDetails(response?.data?.user))
        return response?.data?.user;
      }
    } catch (error) {
      console.error("Error:",error);
      if(error?.response?.data){
       const errorMessage=error.response.data.message
       console.log("getUserData error:",errorMessage)
      }
    }
  };
  
  export const updatePassword =(value)=> async () => {
    try {
        const header=  await getHeader()
      const response = await axios.patch(`${baseUrl}/users/updatePassword`,
      {
        password:value.currentPassword,
        newPassword:value.newPassword
      },{
        headers:header });
      if(response?.status===200){
        toast.success("Password update successfully")
        return response?.data;
      }
    } catch (error) {
      console.error("Error:",error);
      if(error?.response?.data){
       const errorMessage=error.response.data.message
       console.log("update password error:",errorMessage)
       toast.error(errorMessage)
      }
    }
  };

  export const updateUserData =({values,id})=> async (dispatch) => {
    try {
        const header=  await getHeader()
      const response = await axios.patch(`${baseUrl}/users/update/${id}`,
      {
        firstName: values.firstName,
        lastName: values.lastName,
      },{
        headers:header });
      if(response?.status===200){
        toast.success("user data updated successfully")
        dispatch(getUserDetails());
        return response?.data;
      }
    } catch (error) {
      console.error("Error:",error);
      if(error?.response?.data){
       const errorMessage=error.response.data.message
       console.log("update password error:",errorMessage)
       toast.error(errorMessage)
      }
    }
  };