import React from "react";
import style from "./aboutus.module.css";
import { Card, Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import EmpoweringAbout from "./empowering";
import Championabout from "./champions";
import Churchesabout from "./churches";
import Book from "./Book";
import Newsletter from "../home/newsletter";
import Aboutuscard from "./cardpage";
import Supportabout from "./support";
import mike from "../../asserts/mike.png";
import { useEffect } from "react";

const AboutUs = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <>
      <div className={style.aboutimage}>
        <div className="container">
          {/* <img className="w-100" src="/Mask.png" alt="banner" /> */}

          <div className={style.aboutmainp}>
            <p>
              The decision to abort is not made from a place of empowerment,but
              from a place of fear,desperation.
            </p>
            <div className={style.aboutdonbutton}>
              <a href="/donate-to-us/">
                {" "}
                <button>Stand With Us</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <EmpoweringAbout></EmpoweringAbout>
      <Championabout></Championabout>
      {/* <Churchesabout></Churchesabout> */}

      <div className={style.aboutus_row}>
        {/* <div className="my-4 ">
          <div className={style.aboutus_heading}>Who will Stand with Us? </div>
          <div className="p-3">
            {" "}
            No woman wants an abortion. Research shows that six of ten would
            have made a different choice if not for what they perceived as
            insurmountable obstacles that left them with no other choice. These
            are what we’ve identified as the “forgotten women,” along with
            millions of others who are left to struggle with the psychological
            consequences of their ‘choice’. Although their preferred ‘choice’
            and acknowledgment of their struggles have been conveniently omitted
            by the group that supports choice from the abortion debate they
            deserve to be taken into consideration. <br />
            An examination of the facts exposes a sense of injustice when
            wealthy individual donors and over a hundred large corporations,
            including churches, eagerly fund expensive last-minute plane
            tickets, lodging, money for food, traveling outfits, ground
            transportation, etc. Everything a woman needs to abort her baby and
            nothing for the woman who’s struggling to avoid aborting her baby.
            Perhaps for the second or third or maybe fourth time.
          </div>
        </div> */}
        {/* <div className="my-4">
          <Row className="">
            <Col className="" md={6}>
              <div className="text-md-start text-center">
                <img
                  className="w-75"
                  src="/aboutus/aboutus1.webp"
                  alt="story"
                />
              </div>
            </Col>
            <Col className="m-auto" md={6}>
              <div className={style.aboutus_heading}>Who Are We? </div>
              <div className={`p-3 ${style.aboutus_text}`}>
                We are a nonprofit (501c3) organization created to address the
                inequities in overwhelming support given to women who are
                abortion-determined versus that given to those who are
                abortion-vulnerable and are seeking an alternative to abortion.
              </div>
            </Col>
          </Row>
        </div> */}

        {/* <div className="my-4">
          <div className={style.aboutus_heading}>Our Mission </div>
          <div className="p-3">
            {" "}
            <ul>
              <li>
                To raise awareness that it is imperative for society to
                recognize that providing support for women seeking to avoid
                terminating a life is a more significant act of kindness than
                aiding in the termination of one.
              </li>
              <li>
                To help address obstacles that women may perceive as impediments
                to making the life-affirming decision that aligns with their
                conscience.
              </li>
              <li>
                To highlight the need for support for women and men wrestling
                with the emotional consequences of an abortion.
              </li>
            </ul>
          </div>
        </div> */}

        {/* <div className="my-4">
          <Row className="">
            <Col className="m-auto" md={6}>
              <div className={style.aboutus_heading}>How We Do It </div>
              <div className={`p-3 ${style.aboutus_text}`}>
                Through our ongoing fundraising campaign, we funnel money to
                these mom-and-pop ministries run mainly by my moms, who work
                tirelessly in the trenches as the first line of protection for
                women and couples seeking a life-affirming alternative and
                post-abortive care.
              </div>
            </Col>
            <Col md={6}>
              <div className=" text-md-end text-center">
                <img className="w-75" src="/aboutus/aboutus2.png" alt="story" />
              </div>
            </Col>
          </Row>
        </div> */}
        {/* <div className="my-4">
          <div className={style.aboutus_heading}>How You Can Help </div>
          <div className="p-3">
            {" "}
            <ul>
              <li>
                Make a one-time donation of any amount or become a monthly
                partner.
              </li>
              <li>
                Volunteer at your local pregnancy resource center or maternity
                home.
              </li>
              <li>
                Start a diaper, stroller, car seat drive, or infant clothes
                drive month at your church or workplace to help supply their
                material room.
              </li>
              <li>
                Become a trained counselor for post-abortive women and men who
                are struggling to move past their decision.
              </li>
              <li>Start a pregnancy care ministry in your church.</li>
            </ul>
          </div>
        </div> */}
        {/* <div className="my-4">
          <Row className="">
            <Col className="" md={6}>
              <div className="text-md-start text-center">
                <img className="w-75" src="/aboutus/aboutus3.png" alt="story" />
              </div>
            </Col>
            <Col className="m-auto" md={6}>
              <div className={style.aboutus_heading}>Our Vision  </div>
              <div className={`p-3 ${style.aboutus_text}`}>
                To shift societal focus beyond the politics of abortion to one
                where we acknowledge the critical need for compassionate,
                nonjudgmental, easily accessible support from the faith
                community and society at large while addressing the upstream
                factors that are contributing to our abortion-on-demand culture.
              </div>
            </Col>
          </Row>
        </div> */}
        {/* <div className=" mt-5 mb-4">
          <div className={style.aboutus_heading}>Our Core Beliefs</div>
          <div className="p-3">
            {" "}
            <ul>
              <li>
                Respect for Life: We believe that all life is a gift that should
                be respected and protected at whatever stage, age, or condition
                it’s found in from conception to natural death. 
              </li>
              <li>
                Support for All: Every woman who encounters an unplanned
                pregnancy deserves unwavering support, compassionate care.
              </li>
              <li>
                Ideological Unity: Despite ideological differences, everyone
                should agree that reducing the number of lives lost to abortion
                is beneficial for all.
              </li>
            </ul>
          </div>
        </div> */}
        {/* <div className="my-4 ">
          <div className={style.aboutus_heading}>
            Our Greatest Need: Churches{" "}
          </div>
          <div className="p-3">
            {" "}
            The relationship between the church and pregnancy resource centers
            can be likened to a marriage ordained in heaven, where each entity
            complements and supports the other in fulfilling a shared mission.
            Pregnancy resource centers often serve as vital extensions of the
            church's ministry, providing practical support, resources, and
            compassionate care to individuals facing unplanned pregnancies and
            related challenges. Likewise, the church offers spiritual guidance,
            moral support, and a community of faith to individuals served by
            pregnancy resource centers. Together, they form a powerful
            partnership grounded in love, compassion, and a shared commitment to
            upholding the sanctity of human life and supporting individuals in
            need. Just as a marriage thrives on mutual respect, cooperation, and
            shared values, so too does the relationship between the church and
            pregnancy resource centers flourish when grounded in a common vision
            of promoting life, dignity, and hope for all. Through their
            collaborative efforts, they can offer holistic support and care to
            individuals and families, embodying the transformative power of love
            and grace.
          </div>
        </div> */}
        <section id="foundersection">
          <div className="container">
            <div className={style.aboutmaincol}>
              <Row className="m-0">
                {/* Image Column */}
                <Col className="text-center text-md-start p-0" lg={4} md={12}>
                  <div className={style.aboutusimagecontainer}>
                    <img src={mike} alt="story" />
                  </div>
                </Col>
                {/* Text Column */}
                <Col className="m-auto p-0" lg={8} md={12}>
                  <div className={style.abouttextcontainer}>
                    <div className={style.aboutus_heading}>About Me</div>
                    {/* <div className={` ${style.aboutus_text}`}> */}

                    {/* </div> */}
                    <div className={style.abouthead}>
                      <p>
                        I’m often asked why I’m so passionate about this issue.
                        My response is always the same - one word - injustice.
                        Have you ever been on the receiving end of blatant
                        injustice where all you could do was take it? Sure, you
                        have. We all have. It sticks in your craw. You don’t
                        dwell on it, but you never forget it. Because it’s stuck
                        in your craw, you can’t look the other way while an
                        injustice is committed. What we didn’t know then that we
                        know now is that God would someday use it.
                      </p>
                      <p>
                        Abortion is a clear and blatant injustice against a
                        preborn baby who’s been chosen to die for no other
                        reason than an unlucky roll of the dice where they were
                        conceived by someone who felt their right to live should
                        take a back seat to her right to choose.
                      </p>
                      <p>
                        At first glance, you wouldn’t think of it this way, but
                        it's also an injustice against the mother. She’s being
                        exploited—emotional exploitation.
                      </p>
                      <p>
                        How so? She feels trapped, grappling with overwhelming
                        emotions, and now she’s manipulated into making a choice
                        she’d rather not make. But it’s the only one offered.
                        All others are never mentioned. So, like a wild animal
                        caught in a trap who gnaws its leg off to get free
                        because they have no other ‘choice,’ she accepts.
                      </p>
                      {/* <p>
                        They almost flipped me. Yes, me. I asked AI to defend
                        the most common reasons people give for supporting
                        abortion. They were good. They made sense. They almost
                        check-mated me. They would have if not for … but God!
                        Wrong can become right, and right can become wrong when
                        I lean to my own understanding. AI doesn’t’ factor in
                        the power of God.
                      </p> */}
                      <p>
                        Unfortunately for her, this choice has lasting
                        repercussions, such that she can never revisit it or
                        even be present where it’s being revisited. That’s
                        emotional exploitation.
                      </p>
                      <p>
                        The solution she is bombarded with becomes more
                        attractive because it offers the easiest access and
                        quickest relief. Yet it directly contradicts her
                        instinct to nurture and safeguard life. She’s being
                        exploited.
                      </p>
                      <p>
                        It’s touted by elected officials as the ultimate
                        solution.
                      </p>
                      <p>
                        Is abortion the only tool we have that addresses
                        unplanned pregnancies? Or is it the only tool we’re
                        willing to offer her? Whichever way you dice it up, it’s
                        an injustice carried out against our preborn and the
                        mothers of those preborn. It’s time we stopped the
                        exploitation. Women need and deserve alternatives to
                        abortion. The fewer abortions, the better.
                      </p>
                      {/* <p>
                        The father is the one who helps turn a crisis pregnancy
                        into a celebrated pregnancy. Four decades later, I
                        finally managed to let go of the emotional burden I held
                        against her and decided to search for her. When I did,
                        to my great disappointment, she was already deceased.
                        Realizing the key to me discovering information about
                        that side of my family was in the grave, I was left
                        feeling like loose change tossed in a sock
                        drawer—irrelevant. If I had found her, I knew what I
                        would say. After giving her a giant hug in so many
                        words, thank you! Thank you for not terminating my life.
                        Thank you for giving God the opportunity to turn a
                        difficult situation into a miracle. Although abortion
                        was illegal in the year I was born, it was available.
                        For some reason, even under what had to be very
                        difficult circumstances, she chose to give me life.
                        After I finished, two others would be waiting for their
                        turn:<span> my kids.</span>
                      </p> */}
                      {/* <h4>Leads to select a center</h4> */}
                      <h4>Your donation makes a difference</h4>
                      <div className={style.aboutheadbutton}>
                        <a href="/search-center">
                          {" "}
                          <button>Stand With Us</button>
                        </a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </section>
        {/* <div className="my-4">
      
          <Row className="">
            <Col className="m-auto" md={6}>
              <div className={style.aboutus_heading}>About the Founder  </div>
              <div className={`p-3 ${style.aboutus_text}`}>
                Mike Goss is a minister, businessman, husband, father, and
                author of The Blueprint for Ending Abortion in the Church.
                Unapologetically pro-life, he’s convinced he’s found a better
                way forward in addressing unplanned pregnancies that empower
                women and couples to choose life for the preborn baby and
                abundant life for their family and it begins with the church.
              </div>
            </Col>
            <Col className="" md={6}>
              <div className=" text-md-end text-center">
                <img className="w-75" src="/aboutus/aboutus5.png" alt="story" />
              </div>
            </Col>
          </Row>
        </div> */}
        {/* <div className="my-4 "> */}
        {/* <div className={style.aboutus_heading}>
            Why I donated. My personal story{" "}
          </div> */}
        {/* <div className="p-3">
            {" "}
            First, let me say upfront that I am by no means wealthy. For me,
            it’s been 40 years of toil with boots on the ground and a hand to
            the plow. You can be sure that for my wife and I, to donate $100,000
            was a significant sacrifice. And you can also be sure that it is one
            of the best we have ever made. I feel honored that God would give me
            the desire and the ability to contribute to many life-affirming
            organizations that help women tilt the scales in favor of life for
            their babies.
            <br />
            One of the compelling reasons that moved me to take this action is
            seeing the massive outpouring of compassion for women who desire an
            abortion while ignoring the women who are struggling to avoid that
            emotional hit. 
            <br />
            Large corporations, celebrities, pro athletes, and it seems like
            anyone who had ten dollars they could afford to put on a credit card
            was pouring millions into organizations like Planned Parenthood,
            whose coffers are already bursting at the seams. It was
            disheartening to see so many with a hyper-focus on helping women who
            desire an abortion and total disregard for those who need help in
            finding alternative solutions. It should be a discouraging signal to
            everyone to know that we’ve devalued human life, where we will
            gladly support women who want the option to take life but are
            unwilling to support women who seek an option to avoid it.
            <br />
            Seeing that compelled me to stand with pregnancy help centers that
            assist women and couples in crisis situations.  And maternity homes
            that offer a safe space for young girls who have been abandoned and
            those who have experienced sex trafficking and are now pregnant and
            traumatized. And mothers who’ve fallen on hard times and are
            homeless, struggling to regain stability for themselves and their
            children. Some of it went to adoption agencies that help make the
            adoption process more affordable for millions of childless couples
            in the United States who want to adopt but can't because of the high
            costs involved.
            <br />
            There’s another reason for my donation that’s deeply personal. I was
            once one of those unborn babies whose life was hanging by a thread.
            At the time of my conception, my mom and dad were not committed to
            each other or fully committed to me. I never knew my birth mother
            and, for decades, didn’t care to know her since she didn’t care to
            know me. Supposedly, she pawned me off on some people with the
            promise to return, but she never did. Sometime later, my dad, still
            single, came to his senses, found me, and brought me to live with
            him. The father is the one who helps turn a crisis pregnancy into a
            celebrated one. Four decades later, after I finally erased the
            emotional debt, I felt she owed, I sought to find her. When I did,
            she was already deceased. Realizing the possibility of ever knowing
            anything about that side of my family was gone, I felt like loose
            change tossed in a sock drawer—no real value. If I had found her,
            though, I already knew what I would say after I gave her a giant
            hug. In so many words, thank you! Thank you for not ending my life.
            Thank you for allowing God a chance to turn a messed-up situation
            into a miracle. Although abortion was not legal in the year I was
            born, it was available. For whatever reason, even under what had to
            be very stressful circumstances, as all women are in that situation,
            she chose to give me life. After I finished, two others would wait
            for their turn: my kids. 
            <br />
            Knowing my little gift enabled at least one more woman to give God a
            chance at turning another ‘mess’ into a miracle, which was one of
            the greatest feelings I’ve ever experienced. Give it a try
            
          </div> */}
        {/* </div> */}

        {/* <div className="my-4">
          <div className={style.aboutus_heading}>What We Do</div>
          <Row className="text-center">
            <Col md={6}>
              <div>
                <img className="" src="/aboutus/support.svg" alt="story" />
              </div>
              <div>Support Network</div>
              <div>
                We connect women with pregnancy centers that offer a range of
                services , from medical care to counselling and material
                assistance.
              </div>
            </Col>
            <Col md={6}>
              <div>
                <img className="" src="/aboutus/advocacy.svg" alt="story" />
              </div>
              <div>Advocacy</div>
              <div className={style.aboutus_text}>
                We raise awareness about the needs and challenges faced by these
                women and advocate for policies that protect and support them.
              </div>
            </Col>
            <Col md={6}>
              <div>
                <img className="" src="/aboutus/education.svg" alt="story" />
              </div>
              <div>Education</div>
              <div className={style.aboutus_text}>
                We provide educational resources to help women make informed
                decisions and understand their options.
              </div>
            </Col>
            <Col md={6}>
              <div>
                <img className="" src="/aboutus/community.svg" alt="story" />
              </div>
              <div>Community Outreach</div>
              <div>
                Through various programs and events, we reach out to women in
                need and offer them a safe and supportive community.
              </div>
            </Col>
          </Row>
        </div> */}
      </div>
      {/* <div className="greenbg">
        <div className="text-center text-white  w-75 m-auto">
          <div className={`pb-3 greenText`}>Join Us</div>
          <div className="pb-3" style={{ color: "#FEEBC3" }}>
            We invite you to join us in our mission. Whether you want to
            volunteer, donate, or simply learn more about our work, your support
            can make a significant impact. Together, we can ensure that no woman
            feels forgotten.
          </div>
          <div className="pt-2">
            <button
              className="greenbtn"
              type="button"
              onClick={() => navigate("/register")}
            >
              Sign Up Now
            </button>
          </div>
        </div>
      </div> */}
      {/* <Aboutuscard></Aboutuscard> */}

      <div className={style.aboutrow}>
        <div className={style.imagecontainer}>
          <img className="w-100" src="/empower.png"></img>
        </div>
        <div className={style.abouttextheadp}>
          {/* <h1>
          Empowering women who feel pressured into an abortion is true empowerment.
        </h1> */}
          <h1>
            Pro-choice should mean support for women regardless of their choice.
            Whether to terminate their pregnancies or to give birth. Support
            shouldn’t be given only when the ‘choice’ is to end life.
          </h1>
        </div>
      </div>

      <Supportabout></Supportabout>
      <div className={style.helpabout}>
        <h1>24 Hour Hotline:</h1>
        <h1>(800) 712-HELP (4357)</h1>
      </div>

      <Book></Book>
      <Newsletter></Newsletter>
    </>
  );
};

export default AboutUs;
