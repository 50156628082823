import React, { useEffect, useState } from "react";
import style from "./donation.module.css";
import { Col, Row } from "react-bootstrap";

const DonationSucess = () => {
  return (
    <>
      <div className={style.donationsucesscontainer}>
        <div className="container">
        {/* <img className="w-100" src="/Still.svg" alt="banner" /> */}
        <div className={style.donationcontainertext}>
          <h1>Thank you for Standing with Us</h1>
          {/* <h2>Mike Goss</h2> */}
          <p>Because no one wants to be forgotten</p>
        </div>
        </div>
      </div>

      <div className={style.donationthankcontainer}>
        <div className="container">
            <Row className=" align-items-center">
                <Col md={12} lg={12} xl={6}>
        <div className={style.thankcontainertextp}>

                <p>
            Thank you so much for giving. We’ve sent a receipt for your donation
            to yourmail@emailaddress.com. If you have any questions, please
            don’t hesitate to reach out.
          </p>
          </div>
                </Col>
                <Col md={12} lg={12} xl={6}>
                <div className={style.thankcontainertext}>
                <h3>We can Take <br></br>action now to<br></br> Support Forgotten<br></br> Women.</h3>
                </div>
                </Col>
            </Row>
         
        
     
        </div>
      </div>
    </>
  );
};

export default DonationSucess;
