import React, { useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import style from "./auth.module.css";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { forgotPasswordapi } from "../../apis/authApis";
import { useDispatch } from "react-redux";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const [disableButton,setDisableButton]=useState(false)
  return (
    <div
      className="d-flex justify-content-center"
      style={{ minHeight: "70vh" }}
    >
      <Row className="m-0 p-0 w-100">
        <Col xs={12} sm={8} md={6} lg={4} className="mx-auto p-0">
          <div className={style.authBox}>
            <h3 className="text-center mb-4">Forgot Password?</h3>
            <div className="mb-4" style={{ color: "#757575" }}>
              Enter your email address to receive a link to reset your password.
            </div>
            <Formik
              initialValues={{
                email: "",
                submit: null,
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Must be a valid email")
                  .max(255)
                  .required("Email is required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, }
              ) => {
                setDisableButton(true)
                try {
                  setStatus({ success: false });
                  dispatch(forgotPasswordapi(values)).then(()=>{
                    setDisableButton(false)
                  } );
                } catch (err) {
                  setStatus({ success: false });
                  setErrors({ submit: err.message });
                  setDisableButton(false)
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
              }) => (
                <Form noValidate onSubmit={handleSubmit} >
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      className={
                        touched.email && errors.email ? "is-invalid" : ""
                      }
                      type="email"
                      placeholder="Enter email"
                      value={values.email}
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.email && errors.email)}
                    />
                    {touched.email && errors.email && (
                      <Form.Text className=" text-danger">
                        {errors.email}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Button
                    className="w-100 mt-2 authBlubtn"
                    variant="primary"
                    type="submit"
                    disabled={disableButton}
                  >
                    Submit
                   { disableButton &&
                   <Spinner
                   className="ms-3"
                     as="span"
                     animation="grow"
                     size="sm"
                     role="status"
                     aria-hidden="true"
                   />
                   }                    
                  </Button>
                </Form>
              )}
            </Formik>
            <div className="text-center mt-3 mb-4">
              Back to <Link to="/login">login</Link>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgetPassword;
