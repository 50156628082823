import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const TransactionStatus = ({
  showTransactionStatus,
  setShowTransactionStaus,

}) => {
    const navigate=useNavigate()
  const handleClose = () =>{
    navigate("/donation")
    setShowTransactionStaus(false);

  }
  return (
    <>
      <Modal
        show={showTransactionStatus}
        onHide={() => handleClose()}
      >
        <Modal.Header style={{ border: "none" }} closeButton>
          <Modal.Title>
            {/* {status && */}
            <h4 className="text-success" style={{ fontWeight: 900 }}>
              Donation Success
            </h4>
            {/* } */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Transaction Completed sucessfully</div>
          Thank you for your generous donation! Your support makes a significant
          impact.
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TransactionStatus;
