// export const baseUrl=process.env.REACT_APP_API_URL;
// export const baseUrl="http://localhost:5000/v1"
// export const fileBaseUrl="http://localhost:5000/"

export const fileBaseUrl = "https://istandwiththeforgottenwomen.org/api/";
export const baseUrl = "https://istandwiththeforgottenwomen.org/api/v1";

export const getToken = () => {
  const accessToken = JSON.parse(localStorage.getItem("token"));
  if (!accessToken) {
    return null;
  }
  const token = accessToken?.token;
  return token;
};

export const getHeader = async () => {
  const token = await getToken();
  const header = {
    Authorization: "Bearer " + token,
  };
  return header;
};
