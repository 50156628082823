import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import style from "./home.module.css";

const CardPage = () => {
  return (
    <Container className="my-5">
      <div className={style.cardmain}>
      <h2 className={style.cardheading}>Our Impact: Support, Advocacy, Community</h2>
      <Row className={style.cardtextt}>
          <Col md={4} className="mb-4">
           <h1>Policy Change</h1>
           <p>Working on influence legislation that takes in consideration of the need for abortion alternatives.</p>
          </Col>
          <Col md={4} className="mb-4">
          <h1>Financial Aid</h1>
          <p>Raising funds for organizations that affirm life: Pregnancy Resource Centers, Maternity homes, Adoption agencies
          </p>
          </Col>
          <Col md={4} className="mb-4">
        <h1>Volunteer Network</h1>
        <p>Mobilizing a dedicated team of volunteers to provide hands-on support and advocacy.</p>
        </Col>
          {/* <Col md={4} className="mb-4">
          <h1>Education</h1>
          
          <p>Providing workshops on relationship training,parenting skills and fatherhood classes,job training.</p>
          </Col> */}
      </Row>
      {/* <Row className={style.cardtextt}>
        <Col md={4} className="mb-4">
        <h1>Policy Change</h1>
        <p>Working to influence legislation that provides alternatives to abortions for the women who seek it.</p>
        </Col>
        <Col md={4} className="mb-4">
        <h1>Awareness</h1>
        <p>Raising pawareness of the inequitable distribution of support for women who are trying to avoid abortions.</p>
        </Col>
        <Col md={4} className="mb-4">
        <h1>Volunteer Network</h1>
        <p>Mobilizing a dedicated team of volunteers to provide hands-on support and advocacy.</p>
        </Col>
      </Row> */}
      </div>
    </Container>
  );
};

export default CardPage;
