import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import style from "./home.module.css";

const Movement = () => {
  return (
    <div className={style.movementconatiner}>
      <div className={style.movecont2}>
        {/* <div className={style.imagemov}>
            <img  src="/movement.svg"></img>
        </div> */}
        <div className="container">
          
    <div className={style.movetextConatiner}>
        <div className={style.headingmove}> 
        <h1>How You Can Help</h1>
        {/* <h2>Join Our Movement</h2> */}
        </div>
        <Row className={style.movetext}>
          <Col md={4} className={style.movedonate}>
           <h1>Donate</h1>
           <p>Your generous contributions help us provide vital support services to women and couples in need.</p>
          </Col>
          <Col md={4} className={style.movedonate}>
          <h1>Volunteer</h1>
          <p>Join our network of passionate volunteers and make a direct impact.
          </p>
          </Col>
          <Col md={4} className={style.movedonate}>
          <h1>Spread the Word</h1>
          <p>Share our mission with your network: like, follow, share .</p>
          </Col>
      </Row>


      <div className={style.paramove}>
        <h3>Our Greatest Need: Community of Faith</h3>
        <p>What happens to the mind of a person and the moral fabric of a nation that accepts aborting a child without a pang of conscience? – Jesse Jackson 1977.
        </p>
        <div className={style.movea}>
        <a href="/church/" >Learn more.</a>
        </div>
        </div>
        <div className={style.dataheading}>
     {/* <h1>We can never consider it our ‘finest hour’ when the only solution provided to women and couples facing complicated pregnancy decisions ends a life and leave many women hurt.
     .</h1> */}
     <div className={style.datatext}>
       <p>Stand with us in our mission to minimize the needless loss of life and the unnecessary emotional suffering that accompanies it.
       </p>
       <p> Together, we can make a real difference.</p>
     </div>
     {/* <h1>Reach out to us today.</h1> */}
     </div>
  
       {/* <div className={style.movebutton}>
        <a href="/center-register/"><button>Join Us</button></a>
       </div> */}
        </div>
        </div>
          
        </div>
        </div>
   
  );
};

export default Movement;
