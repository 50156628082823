import React from "react";
import style from "./book.module.css";
import { Col, Row } from "react-bootstrap";

const TheChurhMust = () => {
  return (
    <div className={style.thechruchlead_wrapper}>

      <div className="container" >
        <div className={style.thechruchlead_main}>
          <Row className="m-0">
            <Col className={` text-center text-md-start d-flex p-0 ${style.thechruchlead_banner1}`} lg={6} md={12} sm={12} xs={12} >
              <img className={`w-100 ${style.thechruchlead_banner}`} src="/book/book_left.png" alt="story" />
            </Col>
            <Col className="m-auto p-0" lg={6} md={12} sm={12} xs={12} >
              <div className={style.book_heading} >The Faith Community lead the way </div>
              <div className={style.book_text}>
                <h5 className={style.courts_heading_text}>The courts can make abortion illegal, but only God, through his church, can make it unnecessary</h5>
                {/* <p className={style.bymiketitle}>by Mike Goss</p> */}
                <p className={style.courts_p_text}>First Chronicles (12:32) tells us that the men of Issachar understood the times so they knew what they had to do to lead Israel in the right direction. Every generation faces its unique challenge. No previous generation though of God's anointed has been tasked with restoring a reverence for human life. The information here shows you how to shift the focus from. what we may have done to what we must do now.</p>
                <div className={style.church_done_button}>
                  <a href="https://9eb34e-77.myshopify.com/collections/all" target="_blank">
              <button>Order Now</button>
              </a>
            </div>
                {/* <p className={style.prolife_p_text}>54% of women who have had an abortion identify as Catholic or Protestant. 40% attended church regularly at the time of their first abortion. 76% felt that their church couldn't help. There's significant and troubling issue in the church.</p> */}
              </div>
            </Col>

          </Row>
        </div>
      </div>
    </div>

  );
};

export default TheChurhMust;
