import React from "react";
import style from "./book.module.css";
import { Row, Col } from "react-bootstrap";

const Turnround = () => {

  return (
    <div className={style.turnround_wrapper}>
      <div className="container">
        <h3 className={style.trunround_heading}>The Turnaround Must Begin With The People Of Faith</h3>
        <Row>
          <Col md={4}>
          <p className={style.trunround_text}>“I consider myself a pro-choice Christian, but this has caused me to think about this issue from an entirely different perspective. And I think most who read it will feel the same”. – R. Buchanan</p>
          </Col>

          <Col md={4}>
          <p className={style.trunround_text}>“Mike has provided a much-needed compassionate and courageous resource for pastors and everyone in church leadership who must go forth.” 
          -Church deacon </p>
          </Col>

          <Col md={4}>
          <p className={style.trunround_text}>
          “I have been pro-life all my adult life. I’ve never heard anything like this. It really causes you to understand what pro-life should look like”. – M. Taylor
          </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Turnround;
