import React, { useState } from "react";
import style from "./privacypolicy.module.css";
import { Col, Row, Button, Form, InputGroup, Spinner } from "react-bootstrap";
const PrivacyPolicy = () => {
  return (
    <div>
      <div className={style.privacyPolicyContainer}>
        <div className="my-5 ">
          <div className={style.privacyHeading}>
            {" "}
            Privacy Policy
            <br />{" "}
            <div className={style.subHeading}>
              {" "}
              Site Privacy Policy
            </div>
          </div>
        </div>
        <div className="my-5">
          
              <div className={style.sectionHeading}>Privacy Policy </div>
              <div className={style.privacyText}>
                At I Stand With The Forgotten Women.org, your privacy is one of
                our top priorities. This policy describes the privacy practices
                regarding information collected from I Stand With The Forgotten
                Women site visitors.  The I Stand With The Forgotten Women
                Privacy Policy applies only to the I Stand With The Forgotten
                Women site. This policy does not apply whenever visitors leave I
                Stand With The Forgotten Women domains by clicking a link to an
                external site, including the sites of Donorbox.org. The I Stand
                With The Forgotten Women linking policy includes more
                information about links from I Stand With The Forgotten Women to
                external sites.
              </div>
         
        </div>
        <div className="my-5">
         
              <div className={style.sectionHeading}>
                Why Do We Collect Information{" "}
              </div>
              <div className={style.privacyText}>
                Speaking plainly, information (or data) is at the heart of what
                we do as an organization. It means we can function as a charity
                and do things like collecting donations, claiming Gift Aid,
                running our life-changing membership and empowerment programs,
                and keeping in touch with you so you can be part of our
                campaigns and activities. It allows us to keep track of how you
                personally prefer to be contacted (and what kinds of contact you
                definitely don’t want!) so we know how to communicate and how
                not to communicate with you.
              </div>
          
        </div>

        <div className="my-5">
        
              <div className={style.sectionHeading}>
                How We Use The Information We Collect From You{" "}
              </div>
              <div className={style.privacyText}>
                The main ways in which we use the information you provide to us
                is to complete and action or deliver a product or service to you
                that you have reasonably requested. This may include but is not
                limited to processing a donation (including claiming Gift Aid,
                where you have asked that we do this), delivering programs and
                training, running our memberships programs, and conducting
                research. We will also use your information to help provide you
                with goods and services that you’ve bought or requested from us.
                We may also use the information you have provided to get in
                touch with you about our work unless you have specifically told
                us not to. Please note that we will not send you emails or text
                messages about our work unless you have specifically asked us
                to.
              </div>
           
        </div>
        <div className="my-5">
        
              <div className={style.sectionHeading}>
                How We Collect Information{" "}
              </div>
              <div className={style.privacyText}>
                <ul>
                  <li>
                    Make a donation to us, either by mail, online (either
                    directly or by using a third-party donation platform such as
                    DonorBox), over the phone, or via SMS/text donation. We also
                    collect information about you when you make a donation to us
                    through setting up a standing order or Direct Debit or
                    donating via payroll giving (Give As You Earn)
                  </li>
                  <li>Become a I Stand With The Forgotten Women Friend</li>
                  <li>Become a I Stand With The Forgotten Women Ambassador</li>
                  <li>Join our workplace inclusion programs</li>
                  <li>Respond to one of our postal mailings or emails</li>
                  <li>
                    Complete a campaign action online (signing a petition, for
                    example)
                  </li>
                  <li>Sign up for our e-newsletter</li>
                  <li>Make a purchase from our online shop</li>
                  <li>
                    Sign up to one of our inclusion events (conferences and
                    empowerment programs for example)
                  </li>
                  <li>Sign up for one of our fundraising events</li>
                  <li>Register as a Community fundraiser or volunteer</li>
                  <li>Register as a Community fundraiser or volunteer</li>
                  <li>Create an account to use our Proud Employers website</li>
                  <li>
                    Agree to or enquire about leaving a legacy, an in-memory, or
                    a tribute donation
                  </li>
                  <li>Cookie information when you visit our website</li>
                  <li>Sign up to work with us during a Pride event</li>
                  <li>Interact with us over the phone, or on social media</li>
                  <li>
                    When you apply to work for I Stand With The Forgotten Women
                  </li>
                  <li>
                    Contact I Stand With The Forgotten Women through our
                    information service by phone or email
                  </li>
                  <li>Interact with us in some other way</li>
                  <li>
                    How we will not use the information we collect from you
                  </li>
                  <li>
                    We will never sell your data, and we will never share it
                    with other organizations for the purposes of their own
                    marketing. We are not involved in any data swapping schemes.
                  </li>
                </ul>
              </div>
          
        </div>
        <div className="my-5">
         
              <div className={style.sectionHeading}>
                How Long Do We Keep Information For{" "}
              </div>
              <div className={style.privacyText}>
                Our standard practice is to only hold your data for as long as
                it is required. This will vary depending on the activity for
                which the data is being used. However, we are wholly aware of
                our obligations under the Data Protection Act and the GDPR, and
                we make every effort to ensure that we only hold on to your data
                for as long as it is required. In terms of physical records, by
                company law, we are required to retain information related to
                donations (such as donation forms and correspondence) for seven
                years (six years from the end of the financial year it is
                collected), at which time it gets securely destroyed. We also
                use a supporter database to keep a record of your interactions
                with us. This includes financial information such as donation
                history or non-financial information, like details of a
                telephone call to our supporter care team. This information is
                incredibly valuable to us for fundraising and reporting
                purposes, and for better understanding the variety of people who
                support I Stand With The Forgotten Women, so we can better plan
                and position our campaigns and appeals. Because data is such a
                key part of helping us to achieve our goals, we have set out the
                following retention schedule: Where you have made a financial
                contribution or commitment to our work, as a donation, legacy
                gift, or otherwise, we will retain your information indefinitely
                (unless otherwise requested). We have made this decision for two
                reasons – firstly because it helps us to identify the context
                surrounding legacy donations. Another reason is that in our
                experience, it is worthwhile investing in re-engaging supporters
                in these groups. Where you do not fall into one of these groups
                (for instance, if you have only ever signed one of our
                petitions) we will retain your information for a period of time
                we feel proportionate to our business aims and your level of
                engagement. It is, of course, your right to ask that your
                information be erased from our records whenever you wish, but
                please be aware that when we do, we will lose all of your
                preferences, including requests to opt-out from certain types of
                communications. See the information below about your rights.
              </div>
        
        </div>
        <div className="my-5">
       
              <div className={style.sectionHeading}>
                Marketing, Profiling, and Screening{" "}
              </div>
              <div className={style.privacyText}>
                As a charity that invests responsibly in fundraising, we operate
                a number of programs to raise money from individuals. This
                includes sending you letters and other sorts of mail, emails,
                calling you on the phone, and sending you text messages. The law
                varies depending on the channel we use to get in touch with you,
                but in line with our obligations according to the Data
                Protection Act 1998, the GDPR, and the Privacy and Electronic
                Communications (EC Directive) Regulations 2003, we may contact
                you as follows:
              </div>
              <div className={style.sectionHeading}>
                <strong>Mail:</strong>
                <p>
                  we send mail to our supporters except where you’ve
                  specifically told us you don’t want to receive mail from us.
                  The mailings we send out include a number of postal appeals
                  focused on different elements of our work. We also send out
                  our supporter magazine, to give you a closer look at the work
                  we’re doing. We could also get in touch with you about the
                  different ways you can support us, such as through supporter
                  fundraising, attending a program, or by leaving a legacy. You
                  have the right to tell us what types of mailings you’d like to
                  receive (for instance, if you’d prefer to receive just our
                  postal appeals or if you’d rather not receive our supporter
                  magazine). If you’d like to discuss your preferences, please
                  get in touch.
                </p>
              </div>
              <div className={style.sectionHeading}>
                <strong>Email:</strong>
                <p>
                  {" "}
                  we send out a number of email alerts to keep you up to date
                  with our work, and to let you know how you can help. You may
                  be included on several mailing lists through your various
                  connections to I Stand With The Forgotten Women. If you’d like
                  to unsubscribe from one specific list, please click the
                  unsubscribe option at the bottom of your email. Alternatively,
                  you can unsubscribe from all email communication by contacting
                  info@I Stand With The Forgotten Women. If you’ve changed any
                  of your contact details, please contact
                  info@IStandWithTheForgottenWomen For more information about
                  your data please see your rights.
                </p>
              </div>
              <div className={style.sectionHeading}>
                <strong>Phone:</strong>
                <p>
                  we find telephone fundraising a really effective way to
                  connect with our supporters, so we may occasionally call you
                  to let you know about additional ways of supporting our work
                  if you’ve provided us with your telephone number. Phone calls
                  may be recorded for training and quality purposes. At the
                  moment, we call our supporters who have indicated they are
                  happy to hear from us by phone, with some exceptions. These
                  are where you have specifically told us that you wouldn’t like
                  to receive phone calls from us, or where you’re signed up to
                  the Telephone Preference Service. Please note, we will always
                  check if you are registered with the Telephone Preference
                  Service before calling you regarding any marketing activity
                  from us unless you have explicitly opted to receive telephone
                  calls from us. You can opt out of receiving fundraising phone
                  calls by letting us know, or you can opt-out through the
                  Fundraising Preference Service. We try to action all opt-out
                  requests as quickly as possible but we do produce telephone
                  calling files in advance to save on costs. We will action
                  requests to be removed from calling lists within 3 working
                  days, but it can take up to 28 days from the time of your
                  request for you to stop receiving calls.
                </p>
              </div>
              <div className={style.sectionHeading}>
                <strong>SMS:</strong>
                <p>
                  where you’ve provided us with a mobile phone number, we may
                  also send you text messages about our work, but only if you
                  have specifically asked us to. If you’d like to opt-out, you
                  can either reply to our messages with a request to opt-out,
                  let us know, or opt-out via the Fundraising Preference
                  Service. Text requests to opt-out are actioned instantly,
                  otherwise, we aim to act on opt-out requests within 3 days of
                  receipt, but it can take up to 28 days from the time of your
                  request for you to stop receiving texts.
                </p>
              </div>
          
        </div>
        <div className="my-5">
        
              <div className={style.sectionHeading}>Profiling </div>
              <div className={style.privacyText}>
                In order to make our messages to you more relevant, we may use
                your personal information to create a profile of your interests
                and preferences. What this means is that we could use pieces of
                information you’ve either given us directly (such as your age or
                location) or your behavior (your donation history, including the
                amount, frequency, and method through which you give, or any
                campaign actions you’ve taken, including signing petitions) to
                create a profile. This not only gives us an idea of who our
                supporters are but also helps to make sure that when we get in
                touch with you or ask you to take an action on our behalf, it is
                done in a way that is relevant and tailored to you. This enables
                us to identify supporters who are likely to be able to help us
                either in a financial or non-financial capacity, and it’s also
                an effective way for us to maximize the use of our marketing
                budget, so we’re able to help further our cause with the
                resources we have available to us. We consider this one of our
                legitimate interests and as such have considered our position
                carefully to ensure that any activity is implemented in a fair
                and balanced way, taking into account your rights as an
                individual. It is your right to opt-out of your information
                being used for profiling, and there is information on how to do
                this at the end of this section.
              </div>
            
        </div>
        <div className="my-5">
        
              <div className={style.sectionHeading}>Screening </div>
              <div className={style.privacyText}>
                Additionally, as part of our legitimate interests, we have
                considered that screening is an effective way for us to ensure
                that we are contacting you with appropriate and relevant
                messages. We operate a number of different programs to nurture
                support and want to make sure that when we make a request of any
                of our supporters, it’s an appropriate one. As such, we do make
                use of third-party wealth screening companies who combine
                information we provide with publicly available information to
                help us determine which of our supporter programs any particular
                supporter is best suited for. While this does form a crucial
                part of our strategy to secure voluntary income, it is your
                right to be able to opt-out from your information being
                screened, and details of how to do this are at the end of this
                section.
              </div>
          
        </div>
        <div className="my-5">
         
              <div className={style.sectionHeading}>
                How We Use The Information We Collect From You{" "}
              </div>
              <div className={style.privacyText}>
                The main ways in which we use the information you provide to us
                is to complete and action or deliver a product or service to you
                that you have reasonably requested. This may include but is not
                limited to processing a donation (including claiming Gift Aid,
                where you have asked that we do this), delivering programs and
                training, running our memberships programs, and conducting
                research. We will also use your information to help provide you
                with goods and services that you’ve bought or requested from us.
                We may also use the information you have provided to get in
                touch with you about our work unless you have specifically told
                us not to. Please note that we will not send you emails or text
                messages about our work unless you have specifically asked us
                to.
              </div>
       
        </div>
        <div className="my-5">
         
              <div className={style.sectionHeading}>
                Publicly Available Information{" "}
              </div>
              <div className={style.privacyText}>
                As part of the work we do, we occasionally carry out research of
                our own to identify individuals in the public spotlight who may
                be interested in supporting I Stand With The Forgotten Women,
                and also to gain additional insight into current supporters of
                significant public standing. This practice, known as desk
                research, makes use of publicly available information through
                sources such as published rich lists, newspapers, and corporate
                websites, and aids us in identifying opportunities for new major
                donors, event sponsorship, corporate partnerships, event
                speakers, and media opportunities. As a precautionary measure in
                order to ensure our events are safe and effective spaces for
                speakers, facilitators, and participants to share knowledge and
                experiences, we might carry out due diligence on event
                participants. Members of our events team might complete a
                routine check on information that is available in the public
                domain and, in exceptional circumstances, reserve the right to
                refuse entry if necessary. If applicable, a full refund will be
                provided. We may also carry out desk research in the following
                areas: prospective organizations in the public, private and
                third sectors who may be interested in joining our workplace
                programs. From time to time we may contact prospective schools
                who may be interested in attending one of our program delivery
                sessions and in turn joining our education program.
              </div>
          
        </div>
        <div className="my-5">
        
              <div className={style.sectionHeading}>Cookies </div>
              <div className={style.privacyText}>
                Cookies are small pieces of code that are downloaded to your
                computer or mobile device when you visit a website, allowing us
                to recognize your device on subsequent visits. We use cookies to
                monitor the status of our website and ensure that it is running
                efficiently, and we also use cookies to remember your
                preferences. Cookies are anonymous and are not linked to any
                personal information.
              </div>
         
        </div>
        <div className="my-5">
      
              <div className={style.sectionHeading}>
                How To Get In Touch With Us{" "}
              </div>
              <div className={style.privacyText}>
                <p> You can get in touch with us in the following ways.</p>
                <p> By post:</p>
                <p>
                  {" "}
                  Supporter Care Team I Stand With The Forgotten Women.org
                </p>
                <p>700 Milam Street, Suite</p>
                <p>#1300 Houston, Texas 77002</p>
                <p>By email: info@IStandWithTheForgottenWomen.org</p>
              </div>
        
        </div>
        <div className="my-5">
       
              <div className={style.sectionHeading}>
                Updates To This Policy{" "}
              </div>
              <div className={style.privacyText}>
                We may update this policy from time to time. If we make any
                material changes to this policy we will let you know, should we
                have sufficient permissions to do so. We will at this time also
                make it clear on our website that we have updated our terms.
              </div>
        
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
