import React from "react";
import { Button, Modal } from "react-bootstrap";

const CenterRegisterSuccess = ({ showSuccessModal, setShowSucessModal }) => {
  const handleClose = () => setShowSucessModal(false);
  return (
    <>
      <Modal show={showSuccessModal} onHide={() => setShowSucessModal(false)}>
        <Modal.Header style={{ border: "none" }} closeButton>
          <Modal.Title>
            <h4 className="text-primary" style={{ fontWeight: 900 }}>
              Registration Success
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Pregnency Netork Center registerd sucessfully. You will be notified by
          email when Pregnecy Network Center is verified.
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CenterRegisterSuccess;
